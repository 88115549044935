import { Component } from '@angular/core';

@Component({
  selector: 'app-confarence-menber-selection',
  templateUrl: './confarence-menber-selection.component.html',
  styleUrls: ['./confarence-menber-selection.component.scss']
})
export class ConfarenceMenberSelectionComponent {

}
