<ng-container *ngIf="hideHeader" >
  <app-header></app-header>
</ng-container>

<ng-container *ngIf="!hideHeader">

  <div class="top-header">
    <div class="right-menu">
  
  
      <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </div>
  
      <mat-menu #rightdownmenu="matMenu">
        <button mat-menu-item (click)="logoutfintion()">
          <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="logo-wrapper">
    <div class="logo-sub-wrapper">
      <img src="https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/logobanner.webp" alt="" />
    </div>
  </div>
  <div class="header_mainwrapper">
    <div class="header_subwrapper">
      <div class="menu-wrapper" >
        <ul>
          <li routerLinkActive="active">
            <a class="active" > Tarot Toolkit</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>

<div class="tarotCardBody">

    <app-tarot-menu></app-tarot-menu>

    <div id="card7new">
      <div class="tarotCardBody">
          

          <div class="tarotCardWrapperStap1">
              <div class="cardSet1">
                  <!-- card Con -->
                  <div class="new7_1set">
                      <div class="cardCon">



                        <ng-container *ngIf="!cardCurrentImage['img1']">
                          <div class="cardConTop" (click)="selectRandomImage('img1')"></div>
                        </ng-container>
                        <ng-container *ngIf="cardCurrentImage['img1']">
                          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                          <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img1']" class="card_image" />
                          </div>
                        </ng-container>



                          <div class="cardConBottom">
                              <strong>1</strong>
                              <span>sunset</span>

                          </div>
                      </div>

                      <!-- card Con end-->
                      <div class="cardCon">

                          <!-- <div class="cardConTop"> <span
                              *ngFor="let val of selectedCardList; let i = index">

                              <img src="{{val?.card_image}}"
                                  *ngIf="val?.cardPosition == 7 "
                                  (click)="openDialog(1, i,val)">
                          </span></div> -->

                          <ng-container *ngIf="!cardCurrentImage['img2']">
                            <div class="cardConTop" (click)="selectRandomImage('img2')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img2']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img2']" class="card_image" />
                            </div>
                          </ng-container>

                          <div class="cardConBottom">
                              <strong>7</strong>
                              <span>clarity</span>

                          </div>
                      </div>
                      <!-- card Con -->

                      <div class="cardCon">


                        <ng-container *ngIf="!cardCurrentImage['img3']">
                          <div class="cardConTop" (click)="selectRandomImage('img3')"></div>
                        </ng-container>
                        <ng-container *ngIf="cardCurrentImage['img3']">
                          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                          <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img3']" class="card_image" />
                          </div>
                        </ng-container>



                          <div class="cardConBottom">
                              <strong>2</strong>
                              <span>horizon</span>

                          </div>
                      </div>

                      <!-- card Con end-->
                  </div>
                  <!-- card Con -->
                  <div class="new7_2set">
                      <div class="cardCon">

                          <!-- <div class="cardConTop"> <span
                                  *ngFor="let val of selectedCardList; let i = index">

                                  <img src="{{val?.card_image}}"
                                      *ngIf="val?.cardPosition == 3 "
                                      (click)="openDialog(1, i,val)">
                              </span></div> -->

                              <ng-container *ngIf="!cardCurrentImage['img4']">
                                <div class="cardConTop" (click)="selectRandomImage('img4')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img4']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img4']" class="card_image" />
                                </div>
                              </ng-container>



                          <div class="cardConBottom">
                              <strong>3</strong>
                              <span>obstacle</span>

                          </div>
                      </div>

                      <!-- card Con end-->

                      <!-- card Con -->

                      <div class="cardCon">

                          <!-- <div class="cardConTop"> <span
                                  *ngFor="let val of selectedCardList; let i = index">

                                  <img src="{{val?.card_image}}"
                                      *ngIf="val?.cardPosition == 4 "
                                      (click)="openDialog(1, i,val)">
                              </span></div> -->

                              <ng-container *ngIf="!cardCurrentImage['img5']">
                                <div class="cardConTop" (click)="selectRandomImage('img5')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img5']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img5']" class="card_image" />
                                </div>
                              </ng-container>



                          <div class="cardConBottom">
                              <strong>4</strong>
                              <span>strength</span>

                          </div>
                      </div>
                  </div>

                  <!-- card Con end-->

                  <!-- card Con -->
                  <div class="new7_3set">

                      <div class="cardCon">

                          <!-- <div class="cardConTop"> <span
*ngFor="let val of selectedCardList; let i = index">

<img src="{{val?.card_image}}"
*ngIf="val?.cardPosition == 6 "
(click)="openDialog(1, i,val)">
</span></div> -->

<ng-container *ngIf="!cardCurrentImage['img6']">
  <div class="cardConTop" (click)="selectRandomImage('img6')"></div>
</ng-container>
<ng-container *ngIf="cardCurrentImage['img6']">
  <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
  <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img6']" class="card_image" />
  </div>
</ng-container>


                          <div class="cardConBottom">
                              <strong>6</strong>
                              <span>navigation</span>

                          </div>
                      </div>



                      <div class="cardCon">

                          <!-- <div class="cardConTop"> <span
                                  *ngFor="let val of selectedCardList; let i = index">

                                  <img src="{{val?.card_image}}"
                                      *ngIf="val?.cardPosition == 5 "
                                      (click)="openDialog(1, i,val)">
                              </span></div> -->
                              <ng-container *ngIf="!cardCurrentImage['img7']">
                                <div class="cardConTop" (click)="selectRandomImage('img7')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img7']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img7']" class="card_image" />
                                </div>
                              </ng-container>
                              



                          <div class="cardConBottom">
                              <strong>5</strong>
                              <span>advice</span>

                          </div>
                      </div>

                      <!-- card Con end-->

                      <!-- card Con -->


                  </div>
                  <!-- card Con end-->

                  <!-- card Con -->



                  <!-- card Con end-->
              </div>
          </div>




      </div>





  </div>


</div>

<app-footer></app-footer>