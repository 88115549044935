import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthGuardService] },
  {
    path: 'forget-password',
    loadChildren: () =>
      import('./forget-password/forget-password.module').then((m) => m.ForgetPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('./verification/verification.module').then((m) => m.VerificationModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'admin-dashboard',
    loadChildren: () =>
      import('./admin-dashboard/admin-dashboard.module').then((m) => m.AdminDashboardModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'astrologer-dashboard',
    loadChildren: () =>
      import('./astrologer-dashboard/astrologer-dashboard.module').then((m) => m.AstrologerDashboardModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "astrologer-tarot-signup",
    loadChildren: () => import('./astro-tarot-signup/astro-tarot-signup.module').then(m => m.AstroTarotSignupModule)
  },
  {
    path: "customer-signup",
    loadChildren: () => import('./customer-signup/customer-signup.module').then(m => m.CustomerSignupModule)
  },
  {
    path: 'customer-dashboard',
    loadChildren: () =>
      import('./customerdashboard/customerdashboard.module').then((m) => m.CustomerdashboardModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "horoscope",
    loadChildren: () => import('./horosceop/horosceop.module').then(m => m.HorosceopModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "my-account",
    loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule)
  },
  {
    path: "testimonial",
    loadChildren: () => import('./testimonials/testimonials.module').then(m => m.TestimonialsModule)
  },

  {
    path: "tarot-card",
    loadChildren: () => import('./tarot-card/tarot-card.module').then(m => m.TarotCardModule),
    canActivate: [AuthGuardService]
  },
 
  {
    path: "showcasespread",
    loadChildren: () => import('./showcasespread/showcasespread.module').then(m => m.ShowcasespreadModule),    canActivate: [AuthGuardService]
  },
 
  {
    path: "profile/:unique_name",
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
  },
  {
    path: "profile/:unique_name/:id",
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
  },

  {
    path: "sharespread",
    loadChildren: () => import('./sharespread/sharespread.module').then(m => m.SharespreadModule)
  },
  {
    path: "shop",
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: "calender",
    loadChildren: () => import('./calender/calender.module').then(m => m.CalenderModule)
  },
  {
    path: "product",
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
  },
  {
    path: "perennial-mandalism-dashboard",
    loadChildren:()=>import('./perennial-mandalism/perennial-mandalism.module').then(m=>m.PerennialMandalismModule)
  },
  {
    path:"affiate-dashboard",
    loadChildren:()=>import('./affiliate-dashboard/affiliate-dashboard.module').then(m=>m.AffiliateDashboardModule)
  },
  {
    path:"blog-manegement",
    loadChildren:()=>import('./blog-management-routing-module/blog-management-routing-module.module').then(m=>m.BlogManagementRoutingModuleModule)
  },
  {
    path:"video-management",
    loadChildren:()=>import('./video-management/video-management.module').then(m=>m.VideoManagementModule)
  },
  {
    path:"brod-casting",
    loadChildren:()=>import('./brod-casting/brod-casting.module').then(m=>m.BrodCastingModule)
  },
  {
    path:"confarence-room",
    loadChildren:()=>import('./confarence-room/confarence-room.module').then(m=>m.ConfarenceRoomModule)
  },
  {
    path:"recorded-session",
    loadChildren:()=>import('./recorded-session/recorded-session.module').then(m=>m.RecordedSessionModule),
    canActivate: [AuthGuardService]
  }

 


  


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
