export const environment = {
  development: true,
  api_url_lambda: "https://o7j76jozh0.execute-api.us-west-1.amazonaws.com/dev/",
  api_url: "https://d36fwfwo4vnk9h.cloudfront.net/",
  // api_url: "http://localhost:3522/",
  frontend_url_dev: "https://divineinfinitebeing.influxiq.com/",
  frontend_url_prod: "https://divineinfinitebeing.com/",
  astrology_api_url: "https://json.astrologyapi.com/v1/",
  // new_astrology_api_url: 'https://df6rqy88v6l8f.cloudfront.net',
  new_astrology_api_url: 'https://zxbsnyl54lsb34xzkdfigfij7a0mjtlc.lambda-url.us-east-1.on.aws',
  without_base_url:"https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/",
  username: '634485',
  password: '67c08f16f73b3bc2cc48ad1c3b4bd514a582fac9',
  unauthorized_routes: [
    'user/login',
    'user/build-connection',
    'user-profile/fetch-profile-by-unique-name',
  ],
  confarence_room_base_url:"https://main.d1njtuljco40va.amplifyapp.com",
  positionstack_api_key:'f94b49d3a2592c82286e616abcb1b45d',
  positionstack_api_url:'http://api.positionstack.com/v1/forward',
  image_base_url:'https://all-frontend-assets.s3.amazonaws.com'
};
