<app-header></app-header>

<div class="tarotCardBody">

    <app-tarot-menu></app-tarot-menu>

    <div class="tarotCardWrapperStap9">

        <div class="Stap9Body">

            <div class="Stap9BodyRow">

                <!-- card Con -->

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>4</strong>
                        <span>Future
                        </span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Whatlies']">
                        <div class="cardConTop" (click)="selectRandomImage('Whatlies')"></div>
                    </ng-container>
                    <ng-container *ngIf="cardCurrentImage['Whatlies']">
                        <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Whatlies']"
                                class="card_image" />
                        </div>
                    </ng-container>


                </div>

                <!-- card Con end-->



            </div>
            <div class="Stap9BodyRow">

                <!-- card Con -->

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>5</strong>
                        <span>Conscious

                        </span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Whatliesabove']">
                        <div class="cardConTop" (click)="selectRandomImage('Whatliesabove')"></div>
                    </ng-container>
                    <ng-container *ngIf="cardCurrentImage['Whatliesabove']">
                        <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Whatliesabove']"
                                class="card_image" />
                        </div>
                    </ng-container>


                </div>

                <!-- card Con end-->

                <div class="Stap9Con2">
                    <!-- card Con -->

                    <div class="cardCon" (click)="cardConsub1()" [ngClass]="addtopblock==true ? 'addtopblock' : '' ">
                        <div class="cardConBottom">
                            <strong>1</strong>
                                        <span>The Present Situation
                                        </span>

                        </div>
                        <ng-container *ngIf="!cardCurrentImage['CentralIssus']">
                            <div class="cardConTop" (click)="selectRandomImage('CentralIssus')"></div>
                        </ng-container>
                        <ng-container *ngIf="cardCurrentImage['CentralIssus']">
                            <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['CentralIssus']"
                                    class="card_image" />
                            </div>
                        </ng-container>


                    </div>

                    <!-- card Con end-->
                    <!-- card Con -->

                    <div class="cardCon cardCon2" (click)="cardConsub2()"
                        [ngClass]="addtopblock2==true ? 'addtopblock2' : '' ">
                        <div class="cardConBottom">
                            <strong>2</strong>
                            <span>Problem
                            </span>

                        </div>
                        <ng-container *ngIf="!cardCurrentImage['Whatcrossesyou']">
                            <div class="cardConTop" (click)="selectRandomImage('Whatcrossesyou')"></div>
                        </ng-container>
                        <ng-container *ngIf="cardCurrentImage['Whatcrossesyou']">
                            <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Whatcrossesyou']"
                                    class="card_image" />
                            </div>
                        </ng-container>


                    </div>

                    <!-- card Con end-->


                </div>


                <!-- card Con -->

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>3</strong>
                        <span>Past
                        </span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Whatliesbelow']">
                        <div class="cardConTop" (click)="selectRandomImage('Whatliesbelow')"></div>
                    </ng-container>
                    <ng-container *ngIf="cardCurrentImage['Whatliesbelow']">
                        <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Whatliesbelow']"
                                class="card_image" />
                        </div>
                    </ng-container>


                </div>

                <!-- card Con end-->




            </div>
            <div class="Stap9BodyRow">

                <!-- card Con -->

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>6</strong>
                        <span>Unconscious
                        </span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['futurelies']">
                        <div class="cardConTop" (click)="selectRandomImage('futurelies')"></div>
                    </ng-container>
                    <ng-container *ngIf="cardCurrentImage['futurelies']">
                        <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['futurelies']"
                                class="card_image" />
                        </div>
                    </ng-container>

                </div>

                <!-- card Con end-->



            </div>
            <div class="Stap9BodyRow">

                <!-- card Con -->

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>10</strong>
                        <span>Outcome
                        </span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Outcome']">
                        <div class="cardConTop" (click)="selectRandomImage('Outcome')"></div>
                    </ng-container>
                    <ng-container *ngIf="cardCurrentImage['Outcome']">
                        <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Outcome']" class="card_image" />
                        </div>
                    </ng-container>


                </div>

                <!-- card Con end-->
                <!-- card Con -->

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>9</strong>
                        <span>Hopes & Fears
                        </span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Fcars']">
                        <div class="cardConTop" (click)="selectRandomImage('Fcars')"></div>
                    </ng-container>
                    <ng-container *ngIf="cardCurrentImage['Fcars']">
                        <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Fcars']" class="card_image" />
                        </div>
                    </ng-container>


                </div>

                <!-- card Con end-->
                <!-- card Con -->

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>8</strong>
                        <span>External Influenc
                        </span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['environment']">
                        <div class="cardConTop" (click)="selectRandomImage('environment')"></div>
                    </ng-container>
                    <ng-container *ngIf="cardCurrentImage['environment']">
                        <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['environment']"
                                class="card_image" />
                        </div>
                    </ng-container>

                </div>

                <!-- card Con end-->
                <!-- card Con -->

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>7</strong>
                                    <span>Your Influence
                                    </span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Yourself']">
                        <div class="cardConTop" (click)="selectRandomImage('Yourself')"></div>
                    </ng-container>
                    <ng-container *ngIf="cardCurrentImage['Yourself']">
                        <div class="cardConTop" [ngClass]="addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Yourself']"
                                class="card_image" />
                        </div>
                    </ng-container>

                </div>

                <!-- card Con end-->



            </div>



        </div>


    </div>


</div>


<app-footer></app-footer>