<mat-card class="main-title" *ngIf="!data">
    <h2>TAROT TOOLKIT</h2>
</mat-card>



<ul class="tarotMenu" *ngIf="!data">

  <ng-container *ngIf="!hideHeader">
    
    <li [ngClass]="this.currentPath().includes('/tarot-card/tarotCard3') ? 'active': ''" (click)="navigateToPath('tarot-card/tarotCard3')"> 3 Card Basic Question Spread</li>
    

    <li [ngClass]="this.currentPath().includes('/tarot-card/tarotCard7') ? 'active' : ''" (click)="navigateToPath('tarot-card/tarotCard7')"> 5 Card Complex Question Spread </li>

    <li [ngClass]="this.currentPath().includes('/tarot-card/tarotCard5') ? 'active' : '' " (click)="navigateToPath('tarot-card/tarotCard5')"> 7 Card 6 month forward review</li>

    <li  [ngClass]="this.currentPath().includes('/tarot-card/tarotCard1') ? 'active': '' " (click)="navigateToPath('tarot-card/tarotCard1')"> 7 Card Horseshoe Spread (Major read)</li>

    <li [ngClass]="this.currentPath().includes('/tarot-card/tarotCard6') ? 'active' : '' " (click)="navigateToPath('tarot-card/tarotCard6')"> 10 Card Relationship Spread</li>
    
    <li [ngClass]="this.currentPath().includes('/tarot-card/tarotCard9') ? 'active' : '' " (click)="navigateToPath('tarot-card/tarotCard9')">10 Card Celtic Cross (Major read)</li>

    <li [ngClass]="this.currentPath().includes('/tarot-card/tarotCard8') ? 'active' : '' " (click)="navigateToPath('tarot-card/tarotCard8')">12 Card Astrological Spread (Major read)</li>
  
  </ng-container>


  <ng-container *ngIf="hideHeader">

    <ng-container *ngIf="headerConfigaretion.includes('/tarot-card/tarotCard3')">
      <li class="active"> 3 Card Basic Question Spread</li>
    </ng-container>


    <ng-container *ngIf="headerConfigaretion.includes('/tarot-card/tarotCard7')">
      <li class="active">5 Card Complex Question Spread</li>
    </ng-container>

    <ng-container *ngIf="headerConfigaretion.includes('/tarot-card/tarotCard5')">
      <li class="active">7 Card 6 month forward review</li>
    </ng-container>

    <ng-container *ngIf="headerConfigaretion.includes('tarot-card/tarotCard1')">
      <li class="active">7 Card Horseshoe Spread (Major read)</li>
    </ng-container>

    <ng-container *ngIf="headerConfigaretion.includes('tarot-card/tarotCard6')">
      <li class="active">10 Card Relationship Spread</li>
    </ng-container>

    <ng-container *ngIf="headerConfigaretion.includes('tarot-card/tarotCard9')">
      <li class="active">10 Card Celtic Cross (Major read)</li>
    </ng-container>


    <ng-container *ngIf="headerConfigaretion.includes('tarot-card/tarotCard8')">
      <li class="active">12 Card Astrological Spread (Major read)</li>
    </ng-container>

  </ng-container>


</ul>
 
 
