import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-common-preview-modal',
  templateUrl: './common-preview-modal.component.html',
  styleUrls: ['./common-preview-modal.component.css'],
})
export class CommonPreviewModalComponent {
  public datakey: any;
  public datavalue: any;
  public value: any;
  public extractedData: any = {};
  public created_on: any;
  public updated_on: any;
  public created_at: any;
  public createImageUrlArray:any=[];
  public videoArray:any=[]
  public audioArray:any=[]

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<CommonPreviewModalComponent>,private _sanitizer: DomSanitizer) {



    this.datakey = this.data.key;
    this.datavalue = this.data.value;


    console.log("datakey",this.data.key);
    

  }

  ngOnInit() {



    console.log("datavalue",this.datavalue);
    console.log("datavalue",this.datavalue['videos']);


    if(this.datavalue['images']){
      let imgarray = this.datavalue['images']
      for(let i = 0; i<imgarray.length;i++){
        this.createImageUrlArray[i] = environment.image_base_url+"/"+imgarray[i].baseurl+imgarray[i].fileservername
      }
    }

    if(this.datavalue['videos']){
      console.log("videos",this.datavalue['videos']);
      
      for(let j=0 ;j<this.datavalue['videos'].length;j++){
        
        let videoadata = this.datavalue['videos'][j]
        // youtube video 
        if(videoadata.videotype === 1){
          let videocode = videoadata.youtube_link
          this.videoArray[j] = {'type':1,'link':this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+videocode)}
        }

        // vimeo link
        if(videoadata.videotype === 2){
          let videocode = videoadata.vimeo_link
          this.videoArray[j] = {'type':2,'link':this._sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/510890273?h='+videocode)}
        }


        if(videoadata.videotype === 3){
         for(let i = 0; i<videoadata.videofile.length;i++){
            let obj = videoadata.videofile[i]
            this.videoArray[j] = {'type':3,'link':environment.image_base_url+"/"+obj.baseurl+obj.fileservername}
          }
        }
      }
    }


    if(this.datavalue['audios']){
      let audios = this.datavalue['audios']
      console.log("audios",audios);
      for(let i = 0; i<audios.length;i++){
        this.audioArray[i] = environment.image_base_url+"/"+audios[i].baseurl+audios[i].fileservername
      }
    }

    console.log("audios",this.audioArray);
    





    this.datafilter(this.datakey, this.datavalue);
  }



  datafilter(required_keys: any, dataset: any) {

    for (let key of this.datakey) {
      
      if (key === 'created_on') {
        this.created_on = moment(dataset[key]).format(
          'MMMM Do YYYY, h:mm:ss a'
        );
      }
      if (key === 'updated_on') {
        this.updated_on = moment(dataset[key]).format(
          'MMMM Do YYYY, h:mm:ss a'
        );
      }
      if (key === 'created_at' || key === 'createdon_datetime') {
        this.created_at = moment(dataset[key]).format(
          'MMMM Do YYYY, h:mm:ss a'
        );
      }
    }
  }

  closePreview() {
    this.dialogRef.close();
  }
}
