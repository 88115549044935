import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiservicesService } from '../services/apiservices.service';
// import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public loginForm: FormGroup;
  public loader: boolean = false;
  private userIp: any = null;
  hide = true;
  constructor(private apiService: ApiservicesService, private cookieService: CookieService, public router: Router, public matSnackBar: MatSnackBar) {
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
    })
  }
  ngOnInit() {
    // const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    // if (login_user_details?.userinfo?.is_admin == 1) {
    //   this.router.navigateByUrl(`/admin-dashboard`);
    // }
    // else if (login_user_details?.userinfo?.is_astrologer == 1 || login_user_details?.userinfo?.is_tarotreader == 1) {
    //   this.router.navigateByUrl(`/astrologer-dashboard`);
    // }
    // else if (login_user_details?.userinfo?.is_social_advo == 1) {
    //   this.router.navigateByUrl(`/customer-dashboard`);
    // }
    this.apiService.getHttpDataWithoutBaseUrl('https://api.ipify.org?format=json').subscribe({
      next: (getIpResp) => {
        console.log("getIpResp===========>", getIpResp);
        this.userIp = getIpResp?.ip ? getIpResp?.ip : ''
      }
    })
    this.apiService.getHttpData('user/build-connection').subscribe({
      next: (getIpResp) => {
        console.log("Database Connected");
      }
    })

  }

  handleKeyUp(e: any) {
    if (e.keyCode === 13) {
      this.login()

    }
  }

  login() {

    if (this.loginForm.valid) {
      let dataobj = {
        username: this.loginForm.value.email,
        password: this.loginForm.value.password,
        // login_data: this.ipInformation

      }
      this.loader = true;

      this.apiService.getHttpDataWithoutBaseUrl(`https://ipinfo.io/${this.userIp ? this.userIp : ''}?token=a8778567b02081`).subscribe({
        next: (ipresponse) => {
          this.apiService.getHttpDataPost('user/login', { ...dataobj, ipInfo: ipresponse }).subscribe({
            next: (response) => {




              this.cookieService.set('login_user_details', JSON.stringify(response.results), { path: '/', });
              this.cookieService.set('loggedin_user', 'true', { path: '/', });

              this.apiService.getHttpData('astro_decan_new_infos/get-astro-toolkit').subscribe({
                next: (response) => {
                  console.log("astro_decan_new_infos/get-astro-toolkit", response);
                  let obj = {
                    "access_key": response.results.access_key,
                    "secret_key": response.results.secret_key
                  }
                  this.cookieService.set('astro_key_values', JSON.stringify(obj), { path: '/', });
                },
                error: (error) => { }
              })


              this.loader = false;

              this.matSnackBar.open(response.message, "Ok", {
                duration: 3000
              });
              // this.router.navigateByUrl(`/verification?username=${dataobj.username}`);

              if (response.results.userinfo.user_type === 'is_admin') {
                // console.log("response.results.userinfo.user_type", response.results.userinfo.user_type);

                if (sessionStorage.getItem('lastroute')) {
                  let lastRoute = sessionStorage.getItem('lastroute')
                  this.router.navigateByUrl(`${lastRoute}`);
                  setTimeout(() => {
                    sessionStorage.removeItem('lastroute')
                  }, 1500);
                } else {
                  this.router.navigateByUrl(`/admin-dashboard`);
                }

                // this.router.navigateByUrl(`/admin-dashboard`);
              }
              else if (response.results.userinfo.user_type === 'is_astrologer' || response.results.userinfo.user_type === 'is_tarotreader') {
                this.router.navigateByUrl(`/astrologer-dashboard`);
              }
              else if (response.results.userinfo.user_type === 'is_social_advo' || response.results.userinfo.user_type === 'is_customer_socialadvo' || response.results.userinfo.user_type === 'is_customer') {
                this.router.navigateByUrl(`/customer-dashboard`);
              } else if (response.results.userinfo.user_type === 'is_affiliate') {
                this.router.navigateByUrl(`/affiate-dashboard`);
              } else if (response.results.userinfo.user_type === 'is_Perennial_Mandalism') {
                  this.router.navigateByUrl(`/perennial-mandalism-dashboard`)
              }


              // this.router.navigateByUrl(`admin-dashboard`);
              // this.addFormLoader = false;


            },
            error: (error) => {
              console.log("log in funtion ",error);
              this.loader = false;

              if(error.results.message){
                this.matSnackBar.open(error.results.message, "Ok", {
                  duration: 3000
                });
              }

              // this.matSnackBar.open(error.message, "Ok", {
              //   duration: 3000
              // });
              
              if (error.results) {

                if (error.results.email_not_verified == true) {
                  // let verificationdataobj = {
                  //   username: this.loginForm.value.email
                  // }
                  console.log("jhgfhghghg", error.results.email_not_verified, this.loginForm.value.email);
                  let email = this.loginForm.value.email
                  this.apiService.getHttpDatalogin(`user/request-verification-code?username=${email}`).subscribe({
                    next: (response) => {
                      console.log('jhgfhghghg', response);

                      this.loader = false;
                      this.matSnackBar.open(response.message, "Ok", {
                        duration: 4000
                      });
                      this.router.navigateByUrl(`/verification?username=${email}`);
                    },
                    error: (error) => {
                      console.log('jgkgjk', error);

                    }
                  })

                }
              }
            }
          })
        }
      })



    }




  }

  fetchVarification = (data: any) => {

  }
  // keyDownFunction(event) {
  //   if (event.keyCode === 13) {
  //     // if (event.key === "Enter") {}
  //     //alert('you just pressed the enter key');
  //     // rest of your code
  //     this.login();
  //     this.hide = false;


  //   }
  // }
}
