<app-header></app-header>

 

<div class="tarotCardBody">

    <app-tarot-menu></app-tarot-menu>

    <div id="card7">

        <div class="tarotCardBody">
          
            <div class="tarotCardWrapperStap7part2">

                <div class="tarot12cardmainflxsec">


                    <div class="totalgrpwrp">


                    <div class="grp1flx">


                        




                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>1</strong>
                                <span>aries - Personality <br/>
                                  - External</span>

                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img1']">
                                <div class="cardConTop" (click)="selectRandomImage('img1')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img1']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img1']" class="card_image" />
                                </div>
                              </ng-container>


                        </div>



                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>7</strong>
                                <span>libra - <br/>
                                  relationships</span>

                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img2']">
                                <div class="cardConTop" (click)="selectRandomImage('img2')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img2']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img2']" class="card_image" />
                                </div>
                              </ng-container>



                        </div>
                    </div>



                    <div class="grp2flx">

                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>12</strong>
                                <span>pisces - Mystical life<br/>
                                  - dreams/fears</span>


                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img3']">
                                <div class="cardConTop" (click)="selectRandomImage('img3')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img3']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img3']" class="card_image" />
                                </div>
                              </ng-container>





                        </div>
                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>2</strong>
                                <span>taurus - possessions<br/>
                                  /material/security</span>

                            </div>
                            <ng-container *ngIf="!cardCurrentImage['img4']">
                                <div class="cardConTop" (click)="selectRandomImage('img4')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img4']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img4']" class="card_image" />
                                </div>
                              </ng-container>




                        </div>



                    </div>


                    <div class="grp3flx">


                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>11</strong>
                                <span>aquarius - social <br/>
                                  life/friendships</span>

                            </div>
                            <ng-container *ngIf="!cardCurrentImage['img5']">
                                <div class="cardConTop" (click)="selectRandomImage('img5')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img5']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img5']" class="card_image" />
                                </div>
                              </ng-container>


                        </div>


                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>3</strong>
                                <span>gemini - communication<br/>
                                  with others</span>

                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img6']">
                                <div class="cardConTop" (click)="selectRandomImage('img6')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img6']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img6']" class="card_image" />
                                </div>
                              </ng-container>


                        </div>
                    </div>


                    <div class="grp4flx">

                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>10</strong>
                                <span>capricorn - <br/>
                                  reputation/status</span>

                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img7']">
                                <div class="cardConTop" (click)="selectRandomImage('img7')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img7']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img7']" class="card_image" />
                                </div>
                              </ng-container>
                        </div>




                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>4</strong>
                                <span>cancer - home/<br/>
                                  family life</span>

                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img8']">
                                <div class="cardConTop" (click)="selectRandomImage('img8')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img8']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img8']" class="card_image" />
                                </div>
                              </ng-container>




                        </div>
                    </div>


                    <div class="grp5flx">
                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>9</strong>
                                <span>sagittarius - higher<br/>
                                  learning/beliefs</span>

                            </div>
                            <ng-container *ngIf="!cardCurrentImage['img9']">
                                <div class="cardConTop" (click)="selectRandomImage('img9')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img9']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img9']" class="card_image" />
                                </div>
                              </ng-container>




                        </div>
                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>5</strong>
                                <span>leo - love<br/> 
                                  life/creativity</span>

                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img10']">
                                <div class="cardConTop" (click)="selectRandomImage('img10')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img10']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img10']" class="card_image" />
                                </div>
                              </ng-container>




                        </div>
                    </div>

                    <div class="grp6flx">
                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>8</strong>
                                <span>scorpio - major<br/>
                                  changes</span>

                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img11']">
                                <div class="cardConTop" (click)="selectRandomImage('img11')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img11']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img11']" class="card_image" />
                                </div>
                              </ng-container>

                        </div>

                        <div class="cardCon ">
                            <div class="cardConBottom">
                                <strong>6</strong>
                                <span>virgo - work/day<br/> 
                                  to day chores</span>


                            </div>

                            <ng-container *ngIf="!cardCurrentImage['img12']">
                                <div class="cardConTop" (click)="selectRandomImage('img12')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['img12']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img12']" class="card_image" />
                                </div>
                              </ng-container>


                        </div>




                    </div>

                </div>












                </div>
            </div>


        </div>

    </div>


</div>


<app-footer></app-footer>