<mat-card class="main-title" *ngIf="!data">
    <h2>TAROT TOOLKIT</h2>
</mat-card>
<ul class="tarotMenu" *ngIf="!data">

  <li [ngClass]="
  currentPath() == '/tarot-card/tarotCard3'
    ? 'active'
    : ''
" (click)="navigateToPath('tarot-card/tarotCard3')"> 3 Card Basic Question Spread</li>



<li [ngClass]="
currentPath() == '/tarot-card/tarotCard7'
  ? 'active'
  : ''
" (click)="navigateToPath('tarot-card/tarotCard7')"> 5 Card Complex Question Spread </li>



<li [ngClass]="
currentPath() == '/tarot-card/tarotCard5'
  ? 'active'
  : ''
" (click)="navigateToPath('tarot-card/tarotCard5')"> 7 Card 6 month forward review</li>


<li  [ngClass]="
currentPath() == '/tarot-card/tarotCard'
  ? 'active'
  : ''
" (click)="navigateToPath('tarot-card/tarotCard')"> 7 Card Horseshoe Spread (Major read)</li>



<li [ngClass]="
currentPath() == '/tarot-card/tarotCard6'
  ? 'active'
  : ''
" (click)="navigateToPath('tarot-card/tarotCard6')"> 10 Card Relationship Spread</li>



   
    <li [ngClass]="
    currentPath() == '/tarot-card/tarotCard9'
      ? 'active'
      : ''
  " (click)="navigateToPath('tarot-card/tarotCard9')">10 Card Celtic Cross (Major read)</li>

    <li [ngClass]="
    currentPath() == '/tarot-card/tarotCard8'
      ? 'active'
      : ''
  " (click)="navigateToPath('tarot-card/tarotCard8')">12 Card Astrological Spread (Major read)</li>


 
    <!-- <li [ngClass]="
    currentPath() == '/tarot-card/tarotCard2'
      ? 'active'
      : ''
  " (click)="navigateToPath('tarot-card/tarotCard2')"> Personality Spread</li> -->
<!-- 
    <li [ngClass]="
    currentPath() == '/tarot-card/tarotCard4'
      ? 'active'
      : ''
  " (click)="navigateToPath('tarot-card/tarotCard4')"> Transpersonal Mmagick Personality Spread</li> -->
    
</ul>
 
 
