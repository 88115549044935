import { Component } from '@angular/core';
import { ApiservicesService } from '../services/apiservices.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent {
  public seconds = 10;
  public cookieData:any


  constructor(private apiservice: ApiservicesService,public matSnackBar: MatSnackBar,public router: Router,private route:ActivatedRoute,public cookieService: CookieService){

  }

  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    this.startCountdown();
  }

  startCountdown() {
    const interval = setInterval(() => {
      console.log(this.seconds);
      if (this.seconds === 0) {
        clearInterval(interval);
        console.log("Countdown finished!");
        this.logoutfintion()
      } else {
        this.seconds--;
      }
    }, 1000);
  }

  onCountdownComplete() {
    console.log("Executing function after countdown!");

  }

  logoutfintion(){
    this.apiservice.getHttpData(`user/logout/${this.cookieData.userinfo.username}`).subscribe({
      next: (response) => {
        this.cookieService.delete('login_user_details', '/')
        this.cookieService.delete('loggedin_user', '/')
        this.matSnackBar.open(response.message, "Ok", {
          duration: 4000
        });
        this.router.navigateByUrl('/')
      },
      error: (error: any) => {
      }
    })
  }
}
