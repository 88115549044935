<app-header></app-header>

<div class="tarotCardBody">

    <app-tarot-menu></app-tarot-menu>

    <div class="tarotCardWrapperStap3">

        <div class="cardSet3">


            <div class="cardCon">
                <ng-container *ngIf="!cardCurrentImage['Past']">
                    <div class="cardConTop" (click)="selectRandomImage('Past')"></div>
                  </ng-container>
                  <ng-container *ngIf="cardCurrentImage['Past']">
                    <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                    <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Past']" class="card_image" />
                    </div>
                  </ng-container>

                <div class="cardConBottom">
                  <span>1</span>
                    <strong>Past</strong>
                     

                </div>



            </div>
            <div class="cardCon">
                <ng-container *ngIf="!cardCurrentImage['Present']">
                    <div class="cardConTop" (click)="selectRandomImage('Present')"></div>
                  </ng-container>
                  <ng-container *ngIf="cardCurrentImage['Present']">
                    <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                    <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Present']" class="card_image" />
                    </div>
                  </ng-container>

                <div class="cardConBottom">
                  <span>2</span>
                    <strong>Present</strong>
                     

                </div>



            </div>
            <div class="cardCon">
                <ng-container *ngIf="!cardCurrentImage['Future']">
                    <div class="cardConTop" (click)="selectRandomImage('Future')"></div>
                  </ng-container>
                  <ng-container *ngIf="cardCurrentImage['Future']">
                    <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                    <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Future']" class="card_image" />
                    </div>
                  </ng-container>

                <div class="cardConBottom">
                  <span>3</span>
                    <strong>Future</strong>
                     

                </div>



            </div>


        </div>


    </div>


</div>

<app-footer></app-footer>