import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cards-books-poster-details',
  templateUrl: './cards-books-poster-details.component.html',
  styleUrls: ['./cards-books-poster-details.component.scss']
})
export class CardsBooksPosterDetailsComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CardsBooksPosterDetailsComponent>, public router: Router) {

    console.log(data);
    

  }

  goTo(path: any) {

    this.dialogRef.close()
    this.router.navigateByUrl(path)


  }

}
