<app-header></app-header>
<div class="mainwrapper">
    <div class="new-container admin_dashboard_list">
        <!-- <mat-card class="main-title">
            <h2>Dashboard</h2>
        </mat-card> -->
        <app-login-info></app-login-info>
        <div class="subwrapper">            
            
            <div class="sub-title wrapper">
                <h2>Blog List</h2>
                <button mat-button class="add_btn" (click)="addBtn()">Add Blog</button>
            </div>
            <div class="wrapper">
                <div class="list-style1">
                <div class="taxonomies_liststyle" [ngClass]="listprogressBar==true? 'hide':'show' ">
                    <lib-listing class="adminlisttable_v1 "  *ngIf="tabledatatalist != null && tabledatatalist.length > 0"
                        [datasource]="tabledatatalist" [modify_header_array]="modify_header_array" [sourcedata]="tablename" [date_search_source]="date_search_source"
                        [date_search_endpoint]="date_search_endpoint" [datacollection]="datacollection" [apiurl]="api_url_for_managebanner"
                        [updateendpoint]="updateendpoint"  [deleteendpoint]="deleteendpoint" 
                        [search_settings]="search_settings" [skip]="tabledata_header_skip" [date_search_source_count]="date_search_source_count"
                        [libdata]="libdata" [limitcond]="limitcond" [sortdata]="sortdata" [statusarr]="statusarray" [searchendpoint]="searchendpoint" [updatetable]="taxonomy_updatetable"
                        (onLiblistingChange)="listenLiblistingChange($event)" >

                        <!-- [updatetable]="updatetable"  //Removed as it was showing error,add if needed -->
                    </lib-listing>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer> 
