<ng-container *ngIf="hideHeader" >
    <app-header></app-header>
  </ng-container>
  
  <ng-container *ngIf="!hideHeader">
  
    <div class="top-header">
      <div class="right-menu">
    
    
        <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </div>
    
        <mat-menu #rightdownmenu="matMenu">
          <button mat-menu-item (click)="logoutfintion()">
            <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="logo-wrapper">
      <div class="logo-sub-wrapper">
        <img src="https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/logobanner.webp" alt="" />
      </div>
    </div>
    <div class="header_mainwrapper">
      <div class="header_subwrapper">
        <div class="menu-wrapper" >
          <ul>
            <li routerLinkActive="active">
              <a class="active" > Tarot Toolkit</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>






<div class="tarotCardBody">

    <app-tarot-menu></app-tarot-menu>

    <div class="tarotCardWrapperStap7">

        <div class="cardSet7Body">

            <div class="cardSet7BodyBlockBody">

                <div class="cardSet7BodyBlock1">

                    <!-- card Con -->

                    <div class="cardCon">
                        <ng-container *ngIf="!cardCurrentImage['Far-past']">
                            <div class="cardConTop" (click)="selectRandomImage('Far-past')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['Far-past']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Far-past']" class="card_image" />
                            </div>
                          </ng-container>

                        <div class="cardConBottom">
                            <strong>1</strong>
                            <span>Far-past</span>

                        </div>



                    </div>

                    <!-- card Con end-->


                    <!-- card Con -->

                    <div class="cardCon">
                        <ng-container *ngIf="!cardCurrentImage['Past']">
                            <div class="cardConTop" (click)="selectRandomImage('Past')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['Past']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Past']" class="card_image" />
                            </div>
                          </ng-container>


                        <div class="cardConBottom">
                            <strong>2</strong>
                            <span>Past</span>

                        </div>



                    </div>

                    <!-- card Con end-->



                </div>

                <h2>inside</h2>
            </div>
            <div class="cardSet7BodyBlockBody">

                <div class="cardSet7BodyBlock1">

                    <!-- card Con -->

                    <div class="cardCon">
                        <ng-container *ngIf="!cardCurrentImage['Present']">
                            <div class="cardConTop" (click)="selectRandomImage('Present')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['Present']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Present']" class="card_image" />
                            </div>
                          </ng-container>

                        <div class="cardConBottom">
                            <strong>3</strong>
                            <span>Present</span>

                        </div>



                    </div>

                    <!-- card Con end-->


                    <!-- card Con -->

                    <div class="cardCon">
                        <ng-container *ngIf="!cardCurrentImage['Future']">
                            <div class="cardConTop" (click)="selectRandomImage('Future')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['Future']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Future']" class="card_image" />
                            </div>
                          </ng-container>

                        <div class="cardConBottom">
                            <strong>4</strong>
                            <span>Future</span>

                        </div>



                    </div>

                    <!-- card Con end-->



                </div>

                <h2>outside</h2>
            </div>

            <div class="cardSet7BodyBlockBody2">

            <!-- card Con -->

            <div class="cardCon">
                <ng-container *ngIf="!cardCurrentImage['Far-future']">
                    <div class="cardConTop" (click)="selectRandomImage('Far-future')"></div>
                  </ng-container>
                  <ng-container *ngIf="cardCurrentImage['Far-future']">
                    <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                    <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Far-future']" class="card_image" />
                    </div>
                  </ng-container>

                <div class="cardConBottom">
                    <strong>5</strong>
                    <span>Far-future</span>

                </div>



            </div>

            <!-- card Con end-->
            <h2>result</h2>

        </div>

        </div>


      



    </div>

    <div class="cardSet7BodyBottom">

        <div class="cardSet7BodyBottomCon"><h2>nature of the problem</h2>
        <h3>Environment</h3>
        </div>
        <div class="cardSet7BodyBottomCon"><h2>possible solutions</h2></div>


    </div>


</div>

<app-footer></app-footer>