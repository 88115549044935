<div class="login_mainwrapper">
    <div class="login_subwrapper">
        <div class="logo-mainwrapper">
            <div class="logo-subwrapper">
                <img src="https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/logobanner.webp" alt="">
            </div>
        </div>
        <h2>Login</h2>
        
<!-- hellow woarld -->
        <form class="login-form" (ngSubmit)="login()" autocomplete="off" name="loginForm" [formGroup]="loginForm">
            <div class="inputwrapper">
                <div class="iconwrapper"><i class="fa fa-user" aria-hidden="true"></i></div>
                <mat-form-field class="login-full-width">
                    <input matInput placeholder="Email" name="email" formControlName="email" required (keyup)="handleKeyUp($event)">
                </mat-form-field>
                <ng-container
                    *ngIf="!loginForm.controls['email'].valid && loginForm.controls['email'].touched && loginForm.controls['email'].value==''">
                    <span class="error">Email is Required.</span>
                </ng-container>
            </div>
            <div class="inputwrapper">
                <div class="iconwrapper"><i class="fa fa-key" aria-hidden="true"></i></div>
                <mat-form-field class="login-full-width">
                    <input matInput [type]="hide ? 'password' : 'text'" type="password" placeholder="Password" name="password" formControlName="password" required (keyup)="handleKeyUp($event)">
                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide;$event.preventDefault()" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <ng-container *ngIf="!loginForm.controls['password'].valid && loginForm.controls['password'].touched">
                    <span class="error"> Password is Required.</span>
                </ng-container>
            </div>            
            <button mat-raised-button class="submit" [disabled]="loader" [ngClass]="loader==true? 'disabled':'' " >Login</button>
            <a [routerLink]="['/forget-password/send-mail']">Forgot Password?</a>
        </form>
        <mat-progress-bar *ngIf="loader" mode="indeterminate"></mat-progress-bar>
    </div>
</div>

