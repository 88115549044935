import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonPreviewModalComponent } from 'src/app/common-components/common-preview-modal/common-preview-modal.component';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-brodcasting',
  templateUrl: './edit-brodcasting.component.html',
  styleUrls: ['./edit-brodcasting.component.scss']
})
export class EditBrodcastingComponent {

  public listprogressBar: any = false;
  public tabledatatalist: any = [];

  tablename = "brodcasting";
  date_search_source_count: any = 0;
  date_search_source: any = "brodcasting-management";
  date_search_endpoint: any = "brodcasting/brodcasting-list";
  datacollection: any = "brodcasting/brodcasting-list";
  updateendpoint = "brodcasting/brodcasting-status-change";
  deleteendpointmany: "brodcasting/brodcasting-deletemany"
  deleteendpoint = "brodcasting/brodcasting-deletemany";
  tabledata_header_skip: any = ["_id"];
  searchendpoint = "brodcasting/brodcasting-list";
  public taxonomy_updatetable: boolean = false;

  public api_url_for_managebanner = environment.api_url



  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" }
  ];

  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };

  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["title", "created_on", "updated_on", "status", "priority"],
  };

  addBtn() {
    this.router.navigateByUrl(`brod-casting/add-blog`); 
  }

  constructor(public dialog: MatDialog,public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, private apiservice: ApiservicesService) {}

  modify_header_array: any = {
    title: "Title",
    shortDescription: "ShortDescription",
    description: "Description",
    updatedOn: "Updated on",
    status:"Status",
  };

  search_settings: any = {
    search: [
      {
        label: "Search By Title",
        field: '_id',
        values: '',
        serversearchdata: { endpoint: 'brodcasting/brodcasting-autocomplete' }
      },
    ],
    datesearch: [
      {
        startdatelabel: 'Updated on Start Date',
        enddatelabel: 'Updated on End Date',
        submit: 'Search',
        field: 'updated_on',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      }
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },
    ]
  }

  libdata: any = {
    basecondition: {},

    detailview_override: [
      { key: "title", val: "Title" }, 
      { key: "description", val: "Description" },
      { key: "available_for_perennial", val: "Available for Perennial" },
      { key: "created_on", val: "Created on" },
      { key: "updated_on", val: "Updated on" },
      { key: "priority", val: "Priority" },
      { key: "status", val: "Status" },
      { key: "images", val: "Images" },
      { key: "audios", val: "Audios" },
      { key: "files", val: "Files" },
      { key: "videos", val: "Videos" }
    ],

    deleteendpointmany: "brodcasting/brodcasting-delete",
    updateendpoint: "brodcasting/brodcasting-status-change",
    hidedeletebutton: false,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "brodcasting/brodcasting-status-change",

    tableheaders: ["title", "shortDescription", "description", "updatedOn", "status"],
    colpipes: [
      { type: 'datetime', col: 'created_on', format:'MMM D, YYYY' },
    ],

    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
    ]
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe({
      next: (res: any) => {
        this.tabledatatalist = res?.response?.results?.res && Array.isArray(res.response.results.res) ? res.response.results.res : [];
        this.apiservice.getHttpDataPost(
          'brodcasting/brodcasting-list-count',
          {
            "condition": {
              "limit": 10,
              "skip": 0,
            },
            "sort": {
              "type": 'desc',
              "field": '_id'
            },
            "searchcondition": {}
          }
        ).subscribe((response: any) => {
          if (response && response.count) {
            this.date_search_source_count = response.count;
          }
        })
      },
      error: (error: any) => {
        console.log('error --------->', error);
      }
    });
  }

  listenLiblistingChange(data: any = null) {
    console.log("test", data);
    if (data?.custombuttonclick?.btninfo.id == 'edit_btn') {
      this.editListItem(data.custombuttonclick.data);
    }
    if (data.action === "custombuttonclick" && data.custombuttonclick.btninfo.id == "preview_btn") {
      // this.previewModalFunction(data.custombuttonclick.data)
      const dialogRef = this.dialog.open(CommonPreviewModalComponent, {
        panelClass: 'custom-modalbox',
        data: {
          key: data.custombuttonclick.btninfo.previewlist,
          value: data.custombuttonclick.data
        },
      })
    }
  }

  editListItem(item: any) {
    if (item) {
      this.router.navigateByUrl(`brod-casting/edit/${item._id}`);
    }
  }

}
