<app-header></app-header>

<div class="tarotCardBody">

    <app-tarot-menu></app-tarot-menu>

    <!-- <div class="tarotCardWrapperStap6">

        <div class="cardSet6">



            <div class="cardSet6Row">

              

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>6</strong>
                         <span>The Outcome</span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Outcome']">
                        <div class="cardConTop" (click)="selectRandomImage('Outcome')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Outcome']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Outcome']" class="card_image" />
                        </div>
                      </ng-container>


                </div>

               


            </div>

            <div class="cardSet6Row2">

              

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>4</strong>
                         <span>Past Influences</span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Influences']">
                        <div class="cardConTop" (click)="selectRandomImage('Influences')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Influences']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Influences']" class="card_image" />
                        </div>
                      </ng-container>



                </div>

               

                   

                     <div class="cardCon ">
                        <div class="cardConBottom">
                            <strong>5</strong>
                             <span>Future Influences</span>
    
                        </div>
                        <ng-container *ngIf="!cardCurrentImage['Future']">
                            <div class="cardConTop" (click)="selectRandomImage('Future')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['Future']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Future']" class="card_image" />
                            </div>
                          </ng-container>
    
    
                    </div>
    
                   


            </div>

            <div class="cardSet6Row">

              

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>3</strong>
                         <span>Your Strengths</span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Strengths']">
                        <div class="cardConTop" (click)="selectRandomImage('Strengths')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Strengths']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Strengths']" class="card_image" />
                        </div>
                      </ng-container>


                </div>

               


            </div>

            <div class="cardSet6Row">

              

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>2</strong>
                         <span>The Challenge</span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['Challenge']">
                        <div class="cardConTop" (click)="selectRandomImage('Challenge')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Challenge']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Challenge']" class="card_image" />
                        </div>
                      </ng-container>

                </div>

               


            </div>

            <div class="cardSet6Row">

              

                <div class="cardCon ">
                    <div class="cardConBottom">
                        <strong>1</strong>
                         <span>Your Current Position</span>

                    </div>
                    <ng-container *ngIf="!cardCurrentImage['CurrentPosition']">
                        <div class="cardConTop" (click)="selectRandomImage('CurrentPosition')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['CurrentPosition']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['CurrentPosition']" class="card_image" />
                        </div>
                      </ng-container>


                </div>

               


            </div>



        </div>

    </div> -->

    <div id="card3new1">

      <div class="tarotCardBody">



          <div class="tarotCardWrapperStap8new">

              <div class="tarot10mejorflx">


                  <div class="tarot10blk1sec">
                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>6</strong>
                              <span>beliefs</span>


                          </div>

                          <ng-container *ngIf="!cardCurrentImage['img1']">
                            <div class="cardConTop" (click)="selectRandomImage('img1')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img1']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img1']" class="card_image" />
                            </div>
                          </ng-container>




                      </div>



                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>9</strong>
                              <span>hopes and/<br/>
                                or fears</span>

                          </div>

                          <ng-container *ngIf="!cardCurrentImage['img2']">
                            <div class="cardConTop" (click)="selectRandomImage('img2')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img2']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img2']" class="card_image" />
                            </div>
                          </ng-container>

                      </div>


                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>10</strong>
                              <span>outcome for <br/>
                                the relationship</span>


                          </div>
                          <ng-container *ngIf="!cardCurrentImage['img3']">
                            <div class="cardConTop" (click)="selectRandomImage('img3')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img3']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img3']" class="card_image" />
                            </div>
                          </ng-container>




                      </div>

                  </div>



                  <div class="tarot10blk2sec">
                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>1</strong>
                              <span>distant past <br/>
                                influences</span>


                          </div>

                          <ng-container *ngIf="!cardCurrentImage['img4']">
                            <div class="cardConTop" (click)="selectRandomImage('img4')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img4']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img4']" class="card_image" />
                            </div>
                          </ng-container>

                        


                      </div>



                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>2</strong>
                              <span>recent past <br/>
                                influences</span>

                          </div>

                          <ng-container *ngIf="!cardCurrentImage['img5']">
                            <div class="cardConTop" (click)="selectRandomImage('img5')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img5']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img5']" class="card_image" />
                            </div>
                          </ng-container>



                      </div>


                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>3</strong>
                              <span>Current state of <br/>
                                the relationship</span>


                          </div>

                          <ng-container *ngIf="!cardCurrentImage['img6']">
                            <div class="cardConTop" (click)="selectRandomImage('img6')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img6']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img6']" class="card_image" />
                            </div>
                          </ng-container>



                      </div>


                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>4</strong>
                              <span>future influences</span>


                          </div>
                          <ng-container *ngIf="!cardCurrentImage['img7']">
                            <div class="cardConTop" (click)="selectRandomImage('img7')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img7']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img7']" class="card_image" />
                            </div>
                          </ng-container>


                      </div>



                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>5</strong>
                              <span>External influences</span>


                          </div>

                          <ng-container *ngIf="!cardCurrentImage['img8']">
                            <div class="cardConTop" (click)="selectRandomImage('img8')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img8']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img8']" class="card_image" />
                            </div>
                          </ng-container>


                      </div>

                  </div>



                  <div class="tarot10blk3sec">
                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>7</strong>
                              <span>favorable energy</span>


                          </div>

                          <ng-container *ngIf="!cardCurrentImage['img9']">
                            <div class="cardConTop" (click)="selectRandomImage('img9')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img9']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img9']" class="card_image" />
                            </div>
                          </ng-container>



                      </div>

                      <div class="cardCon ">
                          <div class="cardConBottom">
                              <strong>8</strong>
                              <span>what's working <br/>
                                against</span>


                          </div>

                          <ng-container *ngIf="!cardCurrentImage['img10']">
                            <div class="cardConTop" (click)="selectRandomImage('img10')"></div>
                          </ng-container>
                          <ng-container *ngIf="cardCurrentImage['img10']">
                            <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['img10']" class="card_image" />
                            </div>
                          </ng-container>

                      </div>
                  </div>


              </div>











          </div>


      </div>

  </div>


</div>


<app-footer></app-footer>