
<div class="purchase_modal_main">

  <div class="dialoghead">
    <h1 class="mat-mdc-dialog-title">Details of {{data.title}}</h1>
    <mat-dialog-content>
        <div class="tarrotcardposter_detailswrapper">

          <div class="tarrotcardposter_imgsec">
            <div class="tarrotcardposter_imgwrap">
              <div class="tarrotcardposter_imginnerwrap">
                <img [src]="data.image" alt="">
              </div>
              <ul class="tarrotcardposter_imgcarousel">
                <span class="tarrotcardposter_carousel tarrotcardposter_imgprev">
                  <svg width="8" height="15" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="ZpyMwK"><path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#000" class=""></path></svg>
                </span>
                <li *ngFor="let img of data.carousel">
                  <div class="image_box">
                    <img [src]="img" alt="">
                  </div>
                </li>
                <span class="tarrotcardposter_carousel tarrotcardposter_imgnext">
                  <svg width="8" height="15" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="ZpyMwK"><path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#000" class=""></path></svg>
                </span>
              </ul>
            </div>
            <!-- <div class="tarrotcardposter_cartbtn">
              <button>Buy Now</button>
            </div> -->
          </div>

        <div class="tarrotcardposter_detailssec">
          <div class="content_part">
            <h2>{{data.title}}</h2>
            <h3>{{data.details}}</h3>
            <div class="price_section">
              <h4>Price</h4>
              <h4><strong>{{data.price}}</strong></h4>
            </div>
          </div>

          <div class="tarrotcardposter_cartbtn">
            <button (click)="goTo('/shop/cart')">Buy Now</button>
          </div>
        </div>
        
        </div>
    </mat-dialog-content>
    
  </div>

</div>
  
  <span mat-button mat-dialog-close class="close-btn-modal material-icons" mat-raised-button
  matTooltip="Close"
  matTooltipPosition="below"
    >close</span
  >
