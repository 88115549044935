import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TarotCardRoutingModule } from './tarot-card-routing.module';
import { CardSpreadComponent } from './card-spread/card-spread.component';
import { SharedModule } from '../shared/shared.module';
import { CardSpread2Component } from './card-spread2/card-spread2.component';
import { CardSpread3Component } from './card-spread3/card-spread3.component';
import { CardSpread4Component } from './card-spread4/card-spread4.component';
import { CardSpread5Component } from './card-spread5/card-spread5.component';
import { CardSpread6Component } from './card-spread6/card-spread6.component';
import { CardSpread7Component } from './card-spread7/card-spread7.component';
import { CardSpread8Component } from './card-spread8/card-spread8.component';
import { CardSpread9Component } from './card-spread9/card-spread9.component';
 


@NgModule({
  declarations: [
    // CardSpreadComponent
  
    // CardSpread2Component
  
    // CardSpread3Component
  
    // CardSpread4Component
  
    // CardSpread5Component
  
    // CardSpread6Component
  
    // CardSpread7Component
  
    // CardSpread8Component
  
    // CardSpread9Component
  
    // TarotToolkitComponent
  ],
  imports: [
    CommonModule,
    TarotCardRoutingModule,
    //  SharedModule
  ]
})
export class TarotCardModule { }
