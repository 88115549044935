import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from 'src/app/services/apiservices.service';
@Component({
  selector: 'app-tarot-menu',
  templateUrl: './tarot-menu.component.html',
  styleUrls: ['./tarot-menu.component.scss']
})
export class TarotMenuComponent{

  constructor(public router: Router, private apiService: ApiservicesService, public activateRoute: ActivatedRoute, private cookieService: CookieService, public matSnackBar: MatSnackBar) {
    window.scroll(0, 0);
  }


  @Input() data: any;

  public headerConfigaretion:string = ""
  public hideHeader:boolean = false


  ngOnInit(){
    this.routeDetector()
  }


  routeDetector(){
    this.headerConfigaretion = this.router.url
    console.log('headerConfigaretion',this.headerConfigaretion);
    if(this.headerConfigaretion.includes('diviner') && this.headerConfigaretion.includes('customer')){
      this.hideHeader = true
    }

    console.log("headerConfigaretion hideHeader" , this.hideHeader);
    console.log("headerConfigaretion" , this.headerConfigaretion);
    
    
  }



  navigateToPath(path: string) {
    console.log("path==========>", path)
    this.router.navigateByUrl(path)
  }
  currentPath(): string {
    console.log("this.router.url==========>", this.router.url)
    return this.router.url
  }
}

 
 

