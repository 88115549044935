import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-info',
  templateUrl: './login-info.component.html',
  styleUrls: ['./login-info.component.scss']
})
export class LoginInfoComponent implements OnInit {
  public userName: string = ''
  public lastLogin: number = 0

  constructor(private cookieService: CookieService) { }

  ngOnInit() {
    // console.log("this.cookieService============>", this.cookieService.get('login_user_details'))
    const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (login_user_details?.userinfo?.name) this.userName = login_user_details.userinfo.name;
    if (login_user_details?.userinfo?.last_login_time) this.lastLogin = login_user_details.userinfo.last_login_time;
  }
}
