import { Component, Inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from '../services/apiservices.service';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonPreviewModalComponent } from '../common-components/common-preview-modal/common-preview-modal.component';

@Component({
  selector: 'app-blog-managemnt',
  templateUrl: './blog-managemnt.component.html',
  styleUrls: ['./blog-managemnt.component.scss']
})
export class BlogManagemntComponent {



  public listprogressBar: any = false;
  public tabledatatalist: any = [];
  public cookieData: any = {};
  tabledata_header_skip: any = ["_id"];
  updateendpoint = "blogmanagement/blog-status-change";
  tablename = "blogmanagement";
  editroute = "";
  tabledata_detail_skip: any = ['_id', 'usertype'];
  // public jwttokenformanagebanner = ''
  searchendpoint = "blogmanagement/blog-list";
  public taxonomy_updatetable: boolean = false;

  date_search_source: any = "blogmanagement";
  date_search_source_count: any = 0;
  date_search_endpoint: any = "blogmanagement/blog-list";
  datacollection: any = "blogmanagement/blog-list";

  public api_url_for_managebanner = environment.api_url
  deleteendpointmany: "blogmanagement/blog-management-deletemany"
  deleteendpoint = "blogmanagement/blog-management-delete";

  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];

  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };

  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["title", "available_for_perennial", "created_on", "updated_on", "status", "priority"],
  };

  rolearray: any = [
    { val: {
      is_admin:1
    }, name: "Admin" },
    { val: {
        is_astrologer:1
      }, name: "Astrologer" },
      { val: {
        is_tarotreader:1
      }, name: "Tarotreader" },
      { val: {
        is_social_advo:1
      }, name: "Social Advo" },
  ];

  modify_header_array: any = {
    title: "Title",
    description: "Description",
    available_for_perennial: "Available for perennial",
    created_on: "Created on",
    updated_on: "Updated on",
    priority: "Priority",
    status:"Status",
  };

  constructor(public dialog: MatDialog,public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, private apiservice: ApiservicesService) {}

  search_settings: any = {
    search: [
      {
        label: "Search By Title",
        field: '_id',
        values: '',
        serversearchdata: { endpoint: 'blogmanagement/blog-list-title-autocomplete' }
      },
    ],
    textsearch: [
      { 
        label: 'Search By Priority', field: 'priority' 
      }
    ],
    datesearch: [
      {
        startdatelabel: 'Created On Start Date',
        enddatelabel: 'Created On End Date',
        submit: 'Search',
        field: 'created_on',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
      {
        startdatelabel: 'Updated on Start Date',
        enddatelabel: 'Updated on End Date',
        submit: 'Search',
        field: 'updated_on',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },
    ]
  }

  // public customlistenbutton: any = {
  //   flag: true,
  //   tooltipflag: true,
  //   buttons: [

  //   ],
  // }

  addBtn() {
    this.router.navigateByUrl(`blog-manegement/add-blog`); 
  }

  libdata: any = {
    basecondition: {},

    detailview_override: [
      { key: "title", val: "Title" }, 
      { key: "description", val: "Description" },
      { key: "available_for_perennial", val: "Available for Perennial" },
      { key: "created_on", val: "Created on" },
      { key: "updated_on", val: "Updated on" },
      { key: "priority", val: "Priority" },
      { key: "status", val: "Status" },
      { key: "images", val: "Images" },
      { key: "audios", val: "Audios" },
      { key: "files", val: "Files" },
      { key: "videos", val: "Videos" }
    ],
    
    updateendpoint: "blogmanagement/blog-status-change",

    hidedeletebutton: false,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "blogmanagement/blog-status-change",

    deleteendpointmany: "blogmanagement/blog-management-delete",
    tableheaders: ["title", "description", "available_for_perennial", "created_on", "updated_on", "priority", "status"],
    colpipes: [
      { type: 'datetime', col: 'created_on', format:'MMM D, YYYY' },
    ],

    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'listner',
        id: 'preview_btn',
        tooltip: 'Preview',
        name: 'preview_btn',
        classname: 'previewButton',
        previewlist: ["title", "priority", "description", "status", "available_for_perennial", "created_on", "updated_on", "images","audios", "videos"]
      }
    ],

  }

  ngOnInit() {
    this.activatedRoute.data.subscribe({
      next: (res: any) => {
        this.tabledatatalist = res?.response?.results?.res && Array.isArray(res.response.results.res) ? res.response.results.res : [];
        this.apiservice.getHttpDataPost(
          'blogmanagement/blog-list-count',
          {
            "condition": {
              "limit": 10,
              "skip": 0,
            },
            "sort": {
              "type": 'desc',
              "field": '_id'
            },
            "searchcondition": {}
          }
        ).subscribe((response: any) => {
          if (response && response.count) {
            this.date_search_source_count = response.count;
          }
        })
      },
      error: (error: any) => {
        console.log('error --------->', error);
      }
    });

    // if (this.cookieData) {
    //   this.libdata.notes = {
    //     label: "Notes",
    //     tooltip: 'Add Notes',
    //     listendpoint: "notes/listnotedata",
    //     // deleteendpoint: "notes/deletenotedata",
    //     addendpoint: "notes/addnotedata",
    //     user: this.cookieData.userinfo._id,
    //     currentuserfullname: this.cookieData.name,
    //     header: "fullname",
    //   }
    // }
  }

  // onLiblistingButtonChange(val: any) { }

  listenLiblistingChange(data: any = null) {


    if (data?.custombuttonclick?.btninfo.id == 'edit_btn') {
      this.editListItem(data.custombuttonclick.data);
    }



    if (data.action === "custombuttonclick" && data.custombuttonclick.btninfo.id == "preview_btn") {
      
      const dialogRef = this.dialog.open(CommonPreviewModalComponent, {
        panelClass: 'custom-modalbox',
        data: {
          key: data.custombuttonclick.btninfo.previewlist,
          value: data.custombuttonclick.data
        },
      })



    }



    
  }

  callPreviewData(val:string){


    this.apiservice.getHttpDataPost(
      'blogmanagement/blog-details-fetch',
      {
        "_id":val
      }
    ).subscribe((response: any) => {
      console.log("responce",response);
      let obj = response.results;
      
      const dialogRef = this.dialog.open(previewModal, {
        panelClass:["natal_image_modal","show_more_modal"],
        data:obj
      })


      // if (response && response.count) {
      //   this.date_search_source_count = response.count;
      // }
    })






  }

  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.router.navigateByUrl(`blog-manegement/edit/${item._id}`);
    }
  }
}




@Component({
  selector: 'showModalHouse',
  templateUrl: './preview_component.html',
  standalone: true,
  imports: [SharedModule, CommonModule],
})
export class previewModal {
  public previw_data:any;
  constructor( public dialogRef: MatDialogRef<previewModal>, private apiservice: ApiservicesService, @Inject(MAT_DIALOG_DATA) public data: any ,private sanitizer: DomSanitizer) {console.log(data, 'data+++++++++++++++')}

  ngOnInit() {
    this.previw_data = this.data
  }

  createClass(val:string){
    return val.toLowerCase().replace(/ /g, "-");
  }
 

  onNoClick(): void {
    this.dialogRef.close();
  }
}