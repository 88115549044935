import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardSpreadComponent } from './card-spread/card-spread.component';
import { CardSpread2Component } from './card-spread2/card-spread2.component';
import { CardSpread3Component } from './card-spread3/card-spread3.component';
import { CardSpread4Component } from './card-spread4/card-spread4.component';
import { CardSpread5Component } from './card-spread5/card-spread5.component';
import { CardSpread6Component } from './card-spread6/card-spread6.component';
import { CardSpread7Component } from './card-spread7/card-spread7.component';
import { CardSpread8Component } from './card-spread8/card-spread8.component';
import { CardSpread9Component } from './card-spread9/card-spread9.component';
 

const routes: Routes = [
  
  { path: 'tarotCard', component: CardSpreadComponent  },
  { path: 'tarotCard2', component: CardSpread2Component  },
  { path: 'tarotCard3', component: CardSpread3Component  },
  { path: 'tarotCard4', component: CardSpread4Component  },
  { path: 'tarotCard5', component: CardSpread5Component  },
  { path: 'tarotCard6', component: CardSpread6Component  },
  { path: 'tarotCard7', component: CardSpread7Component  },
  { path: 'tarotCard8', component: CardSpread8Component   },
  { path: 'tarotCard9', component: CardSpread9Component   },
 

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TarotCardRoutingModule { 



}
