import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common'
import { Router } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-card-spread8',
  templateUrl: './card-spread8.component.html',
  styleUrls: ['./card-spread8.component.scss']
})
export class CardSpread8Component {

  public addImgcss:boolean=false;


  public cardImageSource: any = [
    'Debauch_Small.jpg',
    'Domonion_Small.jpg',
    'eight_of_Wands_small_image.jpg',
    'Four_of_Swords_Truce_01.jpg',
    'Four_of_Wands_small_image.jpg',
    'Futility_Small.jpg',
    'hanged-man.jpg',
    'hermit.jpg',
    'Hierophant_Small.jpg',
    'Knight_of_Cups_small_image.jpg',
    'Nine_of_Disks_Gain_small.jpg',
    'orphic-jung-tarot_title_image4.png',
    'orphic-jung-tarot_title_image7.png',
    'orphic-jung-tarot_title_image8.png'
  ]
  public cardCurrentImage: any = {}
  public headerConfigaretion:string = ""
  public hideHeader:boolean = false
  public cookieData: any = {};

  constructor(public router: Router,private apiservice: ApiservicesService,public cookieService: CookieService,public matSnackBar: MatSnackBar){}

  selectRandomImage(value: string) {
    if (this.cardImageSource.length > 0) {
      const randomIndex = Math.floor(Math.random() * this.cardImageSource.length);
      const selectedImage = this.cardImageSource[randomIndex];
      this.cardCurrentImage[value] = selectedImage;
      this.cardImageSource.splice(randomIndex, 1); 
      this.addImgcss = true;
    } else {
      console.log('No more images to select.');
    }
  }
  ngOnInit() {
    

     this.cardImageSource.forEach((src:any) => {
      const img = document.createElement("img");
      img.src = `https://divineinfinitebeing.influxiq.com/assets/tarot-card-images/${src}` ;
     });



     if(this.headerConfigaretion.includes('diviner') && this.headerConfigaretion.includes('customer')){
      this.hideHeader = true
    }

    
  }





  logoutfintion(){
    this.apiservice.getHttpData(`user/logout/${this.cookieData.userinfo.username}`).subscribe({
      next: (response) => {
        this.cookieService.delete('login_user_details', '/')
        this.cookieService.delete('loggedin_user', '/')
        this.matSnackBar.open(response.message, "Ok", {
          duration: 4000
        });
        this.router.navigateByUrl('/')
      },
      error: (error: any) => {
      }
    })
  }





}
