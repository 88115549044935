import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from 'src/app/services/apiservices.service';
@Component({
  selector: 'app-tarot-menu',
  templateUrl: './tarot-menu.component.html',
  styleUrls: ['./tarot-menu.component.scss']
})
export class TarotMenuComponent{

  constructor(public router: Router, private apiService: ApiservicesService, public activateRoute: ActivatedRoute, private cookieService: CookieService, public matSnackBar: MatSnackBar) {
    window.scroll(0, 0);
  }


  @Input() data: any;


  ngOnInit(){
    console.log("data",this.data);

  }



  navigateToPath(path: string) {
    console.log("path==========>", path)
    this.router.navigateByUrl(path)
  }
  currentPath(): string {
    // console.log("this.router.url==========>", this.router.url)
    return this.router.url
  }
}

 
 

