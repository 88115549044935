import { Component, Inject } from '@angular/core';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'listing-angular15';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.scss']
})
export class AddBlogComponent {

  public addFormLoader: boolean = false;
  public loader: boolean = false;
  public userformdata: any = {};
  public formfieldrefreshdata: any = null;
  public formfieldrefresh: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  public webpage_array:any = []
  public videoModalData:any = []
  public paramsId:string=''
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  emailregex: RegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public ckeConfig: any = {
    toolbar: [
      { name: "editing", items: ["Scayt", "Find", "Replace", "SelectAll"]},
      { name: "clipboard",items: ["Cut","Copy","Paste","PasteText","PasteFromWord","-","Undo","Redo"]},
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      { name: "tools", items: ["Maximize", "ShowBlocks", "Preview", "Print", "Templates"]},
      { name: "document", items: ["Source"] },
      { name: "insert", items: ["Image", "Table", "HorizontalRule","SpecialChar","Iframe","imageExplorer"]},
      "/",
      { name: "basicstyles", items: ["Bold","Italic","Underline","Strike","Subscript","Superscript","-","RemoveFormat"]},
      { name: "paragraph", items: [ "NumberedList","BulletedList","-","Outdent","Indent","CreateDiv","-","Blockquote"]},
      { name: "justify", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"]},
      { name: "styles", items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"]}
    ]
  };

  statusarr: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  perennial: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ]

  constructor(private apiservice: ApiservicesService, public matSnackBar: MatSnackBar, public router: Router, public activateRoute: ActivatedRoute) { }

  ngOnInit() {
    this.paramsId = this.activateRoute.snapshot?.params["_id"]
    console.log("paramsId", this.activateRoute.snapshot.params)


    if (this.paramsId) {
    console.log("paramsId", this.paramsId)

      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {

          if (response.response.results.res && response.response.status == "success") {
          console.log("response====>", response.response.results.res);

            this.isedit = true;
            this.editFormData = response.response.results.res;
            console.log("editFormData",this.editFormData );
          }
        }
      });

      
      // this.editForm();
    }
    this.initial()
  }

  fetchWebsiteData(){
    this.apiservice.getHttpData('blog/fetch-all-Blog-category-details-data').subscribe({
      next:(value) =>{
        let array = value.results.res
        for(let i = 0; i < array.length;i++){
          this.webpage_array[i] = {'name':array[i].val,'val':array[i].key,'_id':array[i].key,}
        }
      },
      error(err) {
        
      },
    })
  }

  initial() {
    this.userformdata={
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',

      fields: [
        {
          label: 'Title',
          name: 'title',
          value: this.editFormData && this.editFormData.title ? this.editFormData.title : '',
          type: 'text',
          classname: 'inputclass',
          validations: [{ rule: 'required', message: 'Title is Required' }]
        },

        {
          label: 'Priority',
          name: 'priority',
          value:this.editFormData && this.editFormData.priority ?  this.editFormData.priority: '',
          type: 'number',
          classname: 'inputclass',
          validations: [{ rule: 'required', message: 'Priority is Required' }]
        },

        {
          label: "Description",
          name: "description",
          type: "editor",
          value: this.editFormData && this.editFormData.description ? this.editFormData.description : '',
          ckeConfig: this.ckeConfig,
          hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Description is Required" },
          ]
        },

        {
          label: 'Upload Image',
          name: 'images',
          type: 'file',
          prefix: Date.now(),
          value:this.editFormData?.images && Object.keys(this.editFormData?.images).length > 0? this.editFormData.images: null,
          path: 'divine-infinity-being/blog-image/',
          baseurl: 'divine-infinity-being/blog-image/',
          bucket: 'all-frontend-assets',
          apiurl: this.apiservice.baseUrl + 'user-profile/request-bucket-url',
          apideleteurl:this.apiservice.baseUrl + 'user-profile/delete-image-from-bucket',
          imagefields: [
            { label: "Set as Thumbnail", name: "img_status", type: 'checkbox', value: '' },
          ],
          validations: [
            { rule: 'required', message: "Description is Required" },
          ]
        },

        {
          label: "Upload File",
          name: "files",
          type: 'file',
          prefix: "blogfile-" + Date.now(),
          value: this.editFormData?.files && this.editFormData.files.length>0?this.editFormData.files:null,
          path: 'divine-infinity-being/blog-file/',
          baseurl: 'divine-infinity-being/blog-file/',
          bucket: 'all-frontend-assets',
          apiurl: this.apiservice.baseUrl + 'user-profile/request-bucket-url',
          apideleteurl:this.apiservice.baseUrl + 'user-profile/delete-image-from-bucket',
          imagefields: [
            { label: "File Title", name: "file_title", type: 'text', value: '' },
            { label: "File Desc", name: "file_Desc", type: 'textarea', value: '' }
          ],
          aspectratio: [467 / 638, 467 / 467]
        },

        {
          label: "Upload Audio",
          name: "audios",
          type: 'file',
          prefix: "audiofile-" + Date.now(),
          value: this.editFormData?.audios && this.editFormData.audios?this.editFormData.audios:null,
          path: 'divine-infinity-being/blog-audio/',
          baseurl: 'divine-infinity-being/blog-audio/',
          bucket: 'all-frontend-assets',
          apiurl: this.apiservice.baseUrl + 'user-profile/request-bucket-url',
          apideleteurl:this.apiservice.baseUrl + 'user-profile/delete-image-from-bucket',
      
          imagefields: [
            { label: "Audio Title", name: "audio_title", type: 'text', value: '' },
            { label: "Audio Desc", name: "audio_Desc", type: 'textarea', value: '' }
          ],
          aspectratio: [467 / 638, 467 / 467]
        },

        {
          label: 'Upload Videos',
          name: 'videos',
          type: 'file',
          prefix: Date.now(),
          value: this.editFormData?.videos?.length > 0 ? this.editFormData?.videos : null,
          path: 'divine-infinity-being/blog-video/',
          baseurl: 'divine-infinity-being/blog-video/',
          bucket: 'all-frontend-assets',
          apiurl: this.apiservice.baseUrl + 'user-profile/request-bucket-url',
          apideleteurl: this.apiservice.baseUrl + 'user-profile/delete-image-from-bucket',
          imagefields: [
            { label: "Video Title", name: "video_title", type: 'text', value: '' },
            { label: "Video Desc", name: "video_Desc", type: 'textarea', value: '' }
          ],
          aspectratio: [467 / 638, 467 / 467]
        },

        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData ? this.editFormData.status : 1
        },

        {
          label: 'Only for Perennial Mandalism',
          name: 'available_for_perennial',
          type: 'checkbox',
          val: this.perennial,
          value: this.editFormData ? this.editFormData.available_for_perennial : 1
        },
      ]
    }
  }

  listenFormFieldChange(val: any) {
    console.log("val",val);

    if (val.field && val.field == "fromsubmit") {
      this.loader = true

      
      let formValueObj:any ={
        "title":val.fromval.title?val.fromval.title:undefined,
        "description":val.fromval.description?val.fromval.description:undefined,
        "priority":val.fromval.priority?val.fromval.priority:undefined,
        "status":val.fromval.status?val.fromval.status:undefined,
        "available_for_perennial":val.fromval.available_for_perennial?val.fromval.available_for_perennial:undefined,
        "images":val.fromval.images?val.fromval.images:undefined,
        "files":val.fromval.files?val.fromval.files:undefined,
        "audios":val.fromval.audios?val.fromval.audios:undefined,
        "videos":val.fromval.videos?val.fromval.videos:undefined,
      }


      if(this.paramsId){
        formValueObj['_id'] = this.paramsId
      }


      this.apiservice.getHttpDataPost( `${this.paramsId ?'blogmanagement/blog-edit' :'blogmanagement/blog-add'}`, formValueObj).subscribe((response) => {
        console.log("responce",response);
        
        if (response && response.status == 'success') {
          this.loader = false
          console.log("jeuerytc67");
          
          // console.log("this.loader", this.loader);
          this.matSnackBar.open("Blog Added Successfully", "Ok", {
            duration: 3000
          });
          // this.dialogRef.close();
          this.router.navigateByUrl(`blog-manegement`);
          // this.addFormLoader = false;
          // this.location.back();
        }

        if (response && response.status == 'error') {
          this.loader = false

          // this.addFormLoader = false;
        }
      })
      
    }
  }

}


// export class VideoUploadModal {
//   public videomdata: any = {};

//   constructor(public dialogRef: MatDialogRef<VideoUploadModal>,@Inject(MAT_DIALOG_DATA) public data: DialogData,public router: Router,private apiservice: ApiservicesService, public matSnackBar: MatSnackBar,public cookieService: CookieService) {
//     this.modaldata = this.data
//   } 

//   ngOnInit() {
//     this.videoForm(); 
//       if (this.modaldata.data.videotype && this.modaldata.data.videotype==3) {
//         this.videomdata
//       }
//   }

//   videoForm() {
//     this.videomdata = {
//       successmessage: 'Added Successfully !!', // success message
//       submittext: this.modaldata.data && this.modaldata.data.length>0 ? 'Update' : 'Submit',
//       apiUrl: this.apiservice.baseUrl,
//       resettext: 'Reset',
//       canceltext: 'Cancel',
//       hidecancel:true,
//       hidereset: this.modaldata.data ? true : false,
//       jwttoken: '',
//       fields: [
//         {
//           label: "Video Type",
//           name: "videotype",
//           type: 'select',
//           labelPosition: 'after',
//           val: [{ val: 1, name: 'Youtube Link' }, { val: 2, name: 'Vimeo Link' }, { val: 3, name: 'Video File' }],
//           value: this.modaldata.data.videotype? this.modaldata.data.videotype: null,
//           dependent: [
//             {
//               condval: 3,
//               field:{
//                 label: "Upload Video",
//                 name: "videofile",
//                 type: 'file',
//                 multiple:true,
//                 prefix: "blogvideo-" + Date.now(),
//                 path: 'practicepresence-cancer-backend-angular/blogvideo/',
//                 baseurl: 'practicepresence-cancer-backend-angular/blogvideo/',          
//                 bucket: 'awsbackend-dev-patient-files-test',
//                 apiurl:environment.fileuploadbaseurl+'testimonial/testimonial-media-upload',
//                 apideleteurl:environment.fileuploadbaseurl+'testimonial/testimonial-media-delete',
//                 value: null,
//                 imagefields: [
//                   { label: "Video Title", name: "video_title", type: 'text', value:  '' },
//                   { label: "Video Desc", name: "video_Desc", type: 'textarea', value: '' }
//                 ],
//                 aspectratio: [467 / 638, 467 / 467]
//               },
//             }
//           ]
//         }
//       ]
//     }
//   }
// }