import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // console.log("next================>", next, state, this.router)

    if (next.routeConfig?.path == 'admin-dashboard' && this.authService.isAdminAuthenticated()) return true
    if (next.routeConfig?.path == 'astrologer-dashboard' && (this.authService.isAstrologerAuthenticated() || this.authService.isTarotAuthenticated())) return true
    if (next.routeConfig?.path == 'customer-dashboard' && this.authService.isSocialAdvoAuthenticated()) return true
    if (next.routeConfig?.path == 'showcasespread' && this.authService.isAstrologerAuthenticated()) return true
    if (next.routeConfig?.path == 'horoscope' && this.authService.isAdminAuthenticated() || this.authService.isAstrologerAuthenticated()) return true
    if (next.routeConfig?.path == '') {
      const navigationRoute = this.authService.loggedInNavigation()
      console.log("navigationRoute==============>", navigationRoute)
      if (navigationRoute) this.router.navigateByUrl(navigationRoute);
      return true
    }
    this.router.navigateByUrl('/');
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // if (this.authService.isRouteAuthenticated()) {
    //   return true;
    // }
    // this.router.navigateByUrl('/');
    return false;
  }
}
