

<ng-container *ngIf="hideHeader" >
  <app-header></app-header>
</ng-container>

<ng-container *ngIf="!hideHeader">

  

  <div class="top-header">
    <div class="right-menu">
      <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </div>
  
      <mat-menu #rightdownmenu="matMenu">
        <button mat-menu-item (click)="logoutfintion()">
          <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
        </button>
      </mat-menu>
    </div>
  </div>
  
  
  <div class="logo-wrapper">
    <div class="logo-sub-wrapper">
      <img src="https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/logobanner.webp" alt="" />
    </div>
  </div>


  
  
  <div class="header_mainwrapper">
    <div class="header_subwrapper">
      <div class="menu-wrapper" >
        <ul>
          <li routerLinkActive="active">
            <a class="active" > Tarot Toolkit</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>


<div class="tarotCardBody">
  <app-tarot-menu [data]="no_header"></app-tarot-menu>

  <div class="tarotCardWrapperStap1">
    <div class="cardSet1">
      <!-- card Con -->

      <div class="cardCon">
        <ng-container *ngIf="!cardCurrentImage['past']">
          <div class="cardConTop" (click)="selectRandomImage('past')"></div>
        </ng-container>
        <ng-container *ngIf="cardCurrentImage['past']">
          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
          <img [src]="'assets/tarot-card-images/' + cardCurrentImage['past']" class="card_image" />
          </div>
        </ng-container>

        <div class="cardConBottom">
          <strong>1</strong>
          <span>Past Influences</span>
        </div>
      </div>

      <!-- card Con end-->

      <!-- card Con -->

      <div class="cardCon">
        <ng-container *ngIf="!cardCurrentImage['present']">
          <div class="cardConTop" (click)="selectRandomImage('present')"></div>
        </ng-container>
        <ng-container *ngIf="cardCurrentImage['present']">
          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
          <img [src]="'assets/tarot-card-images/' + cardCurrentImage['present']" class="card_image" />
          </div>
        </ng-container>

        <div class="cardConBottom">
          <strong>2</strong>
          <span>Present Circumstances</span>
        </div>
      </div>

      <!-- card Con end-->

      <!-- card Con -->

      <div class="cardCon">
        <ng-container *ngIf="!cardCurrentImage['future']">
          <div class="cardConTop" (click)="selectRandomImage('future')"></div>
        </ng-container>
        <ng-container *ngIf="cardCurrentImage['future']">
          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
          <img [src]="'assets/tarot-card-images/' + cardCurrentImage['future']" class="card_image" />
          </div>
        </ng-container>

        <div class="cardConBottom">
          <strong>3</strong>
          <span>Upcoming influences</span>
        </div>
      </div>

      <!-- card Con end-->

      <!-- card Con -->

      <div class="cardCon">
        <ng-container *ngIf="!cardCurrentImage['advice']">
          <div class="cardConTop" (click)="selectRandomImage('advice')"></div>
        </ng-container>
        <ng-container *ngIf="cardCurrentImage['advice']">
          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
            <img [src]="'assets/tarot-card-images/' + cardCurrentImage['advice']" class="card_image" />
          </div>
        </ng-container>

        <div class="cardConBottom">
          <strong>4</strong>
          <span>Best Course of Action</span>
        </div>
      </div>

      <!-- card Con end-->

      <!-- card Con -->

      <div class="cardCon">
        <ng-container *ngIf="!cardCurrentImage['outside_influence']">
          <div class="cardConTop" (click)="selectRandomImage('outside_influence')"></div>
        </ng-container>
        <ng-container *ngIf="cardCurrentImage['outside_influence']">
          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
          <img [src]="
              'assets/tarot-card-images/' +
              cardCurrentImage['outside_influence']
            " class="card_image" />
            </div>
        </ng-container>

        <div class="cardConBottom">
          <strong>5</strong>
          <span>The Attitude of Others</span>
        </div>
      </div>

      <!-- card Con end-->

      <!-- card Con -->

      <div class="cardCon">
        <ng-container *ngIf="!cardCurrentImage['hope_and_fear']">
          <div class="cardConTop" (click)="selectRandomImage('hope_and_fear')"></div>
        </ng-container>
        <ng-container *ngIf="cardCurrentImage['hope_and_fear']">
          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
          <img [src]="
              'assets/tarot-card-images/' + cardCurrentImage['hope_and_fear']
            " class="card_image" />
            </div>
        </ng-container>

        <div class="cardConBottom">
          <strong>6</strong>
          <span>Possible Obstacles</span>
        </div>
      </div>

      <!-- card Con end-->

      <!-- card Con -->

      <div class="cardCon">
        <ng-container *ngIf="!cardCurrentImage['overall_outcome']">
          <div class="cardConTop" (click)="selectRandomImage('overall_outcome')"></div>
        </ng-container>
        <ng-container *ngIf="cardCurrentImage['overall_outcome']">
          <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
          <img [src]="
              'assets/tarot-card-images/' + cardCurrentImage['overall_outcome']
            " class="card_image" />
            </div>
        </ng-container>

        <div class="cardConBottom">
          <strong>7</strong>
          <span>Overall outcome</span>
        </div>
      </div>

      <!-- card Con end-->
    </div>
  </div>
</div>
<ng-conainer *ngIf="!no_header">
  <app-footer></app-footer>
</ng-conainer>

