
<div class="cmn_preview_modal_main_width">

<span class="close-btn-modal material-icons" (click)="closePreview()" matTooltip="Close" matTooltipPosition="below"> close </span>
<div class="PreviewModalBox">
    <h2>Preview</h2>    
    <div class="PreviewModalBoxCon">
        <ng-container  *ngFor="let data of datakey">
            <div class="mdl_txt" *ngIf="data !== 'images' && data !== 'image' && data !== 'videos' && data !== 'audios' && data !== 'description'">
                <span>
                    {{data === "created_at" ? "Created On": data === "created_on"? "Created On": data === "user_type_str"? "User Type": data === "addedby_email" ? "Added By Email" : data === "addedby_name" ? "Added By" : data === "addedfor_email" ? "Added For Email" : data === "addedfor_name" ? "Added For" : data === "clickCount" ? "Click Count" : data === "conversionCount" ? "Conversion Count" : data === "web_name" ? "Website Name" : data.replaceAll("_", " ")}}:
                </span>
                <span>
                    {{data == "status"? datavalue[data] === 1? "Active": "Inactive": data === "created_on"? created_on: data === "updated_on"? updated_on: data === "created_at"? created_at: data === "image"? "": data === "email"? datavalue[data]: datavalue[data]? datavalue[data]: "N/A"}}
                </span>
            </div>

            <ng-container *ngIf="data === 'description'">
              <div class="mdl_txt">
                <span>
                    Description : 
                </span>
                 <span [innerHTML]="datavalue[data]">
                </span>
              </div>
            </ng-container>

            <ng-container *ngIf="data === 'images' && createImageUrlArray.length >0">
              <div class="mdl_txt">
                <div class="image_wrpr">
                
                    <span>Images : </span>
                        <div class="img_box">
                          <span class="img_sec_main">
                            <ng-container *ngFor="let img of createImageUrlArray">
                                <img [src]="img" alt="">
                            </ng-container>
                        </span>
                    </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="data == 'image'">
                <div class="mdl_txt">
                  <div class="image_wrpr">
                  
                      <span>Image : </span>
                          <div class="img_box">
                            <span>
                             
                                  <img [src]="datavalue[data]" alt="">
                              
                          </span>
                      </div>
                  </div>
                </div>
              </ng-container>


            <ng-container *ngIf="data === 'videos' && videoArray.length >0">
              <div class="mdl_txt">
                <div class="video_wrpr">
                    <span>
                        videos : 
                    </span>
                    
                    <div class="vdo_box">
                      <span>
                        <ng-container *ngFor="let img of videoArray">

                            <ng-container *ngIf="img.type === 2">
                              <div class="vdo_sub_wrpr">
                                <iframe [src]="img.link" frameborder="0"></iframe>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="img.type === 1">
                              <div class="vdo_sub_wrpr">
                                <iframe [src]="img.link" frameborder="0"></iframe>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="img.type === 3">
                              <div class="vdo_sub_wrpr">
                                <video controls  #videoPlayer>
                                    <source src="{{img.link}}" type="video/mp4" />
                                    Browser not supported
                                </video>
                              </div>
                            </ng-container>

                        </ng-container>
                      </span>
                    </div>
                </div>
              </div>
            </ng-container>



        <ng-container *ngIf="data === 'audios' && audioArray.length >0">
          <div class="mdl_txt">
            <span>Audios :</span>
            <span>
                <ng-container *ngFor="let audio of audioArray">
                    <audio controls> <source [src]='audio' type="audio/mpeg" /> </audio> 
                </ng-container>
            </span>
          </div>
        </ng-container>
                
</ng-container>
</div>

</div>
