<app-header></app-header>
<div class="mainwrapper">
   
        <div class="new-container">
            <mat-card class="main-title">
                <h2>Add Blog</h2>
            </mat-card>
            <div class="subwrapper">
                <div class="formStyle user-form">
                    <mat-card-content class="addEditPageWrapper practice_form_style form_title_style">
                        <div class="AddEditBlog taxonomies_formstyle">
                            <div class="addEditForm">
                                <lib-showform [formdata]="userformdata" [formfieldrefresh]="formfieldrefresh"
                                    [formfieldrefreshdata]="formfieldrefreshdata"
                                    (onFormFieldChange)="listenFormFieldChange($event)">
                                </lib-showform>

                                <ng-container *ngIf="loader">
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                </ng-container>


                            </div>
                        </div>

                    </mat-card-content>

                </div>
            </div>
        </div>
   
</div>
    <app-footer></app-footer>
