import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {
  constructor(private cookieService: CookieService) { }

  public loggedInNavigation(): any {
    // console.log("this.cookieService", JSON.parse(this.cookieService.get('login_user_details')))
    const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (login_user_details?.userinfo?.user_type ==='is_admin') return '/admin-dashboard'
    if (login_user_details?.userinfo?.user_type === 'is_astrologer' || login_user_details?.userinfo?.user_type === 'is_tarotreader') return '/astrologer-dashboard'
    if (login_user_details?.userinfo?.user_type ==='is_social_advo') return '/customer-dashboard'
    return null
  }

  public islogin(): any {
    // console.log("this.cookieService", JSON.parse(this.cookieService.get('login_user_details')))
    const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (login_user_details?.userinfo?.user_type ==='is_admin') return true
    if (login_user_details?.userinfo?.user_type === 'is_astrologer' || login_user_details?.userinfo?.user_type === 'is_tarotreader') return true
    if (login_user_details?.userinfo?.user_type ==='is_social_advo') return true
    if (login_user_details?.userinfo?.user_type ==='is_trainee') return true
    if (login_user_details?.userinfo?.user_type ==='is_customer') return true
    return null
  }


  public isAdminAuthenticated(): boolean {
    // console.log("this.cookieService", JSON.parse(this.cookieService.get('login_user_details')))
    const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (login_user_details?.userinfo?.user_type === 'is_admin') return true
    return false
  }

  public isAstrologerAuthenticated(): boolean {
    // console.log("this.cookieService", this.cookieService.get('login_user_details'))
    const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (login_user_details?.userinfo?.user_type ==='is_astrologer') return true
    return false
  }

  public isTarotAuthenticated(): boolean {
    // console.log("this.cookieService", this.cookieService.get('login_user_details'))
    const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (login_user_details?.userinfo?.user_type === 'is_tarotreader') return true
    return false
  }

  public isBothAuthenticated(): boolean {
    // console.log("this.cookieService", this.cookieService.get('login_user_details'))
    const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (login_user_details?.userinfo?.user_type === 'is_astrologer_tarotreader' ) return true
    return false
  }

  public isSocialAdvoAuthenticated(): boolean {
    // console.log("this.cookieService", this.cookieService.get('login_user_details'))
    const login_user_details: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (login_user_details?.userinfo?.user_type ==='is_social_advo' || login_user_details?.userinfo?.user_type ==='is_customer_socialadvo' || login_user_details?.userinfo?.user_type ==='is_customer') return true
    return false
  }



  public convertTo12Hour(time24: string): string {
    const [hours24, minutes] = time24.split(':').map(Number);
    const hours12 = hours24 % 12 || 12; // Convert 0 or 12 to 12
    const period = hours24 >= 12 ? 'PM' : 'AM';
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  }





}