import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { buffer, catchError, map,of, Observable, Subject, Subscription, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { delay, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiservicesService {
  public baseUrl: any = environment.api_url;
  public jwtToken: string = '';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: '',
    }),
  };
  // httpOptions2 = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'authorization': 'Basic ' + btoa(environment.username + ':' + environment.password),
  //   })
  // }

  httpOptions3 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }


  httpOptionsroomID = {
    headers: new HttpHeaders({
        'authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiIyY2ZlNzA0Yy00MTlhLTQ5NWQtYTIyMi01ZTk3MWNlNDdlZTQiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY5NzQzMTAxNSwiZXhwIjoxNjk3NTE3NDE1fQ.N6g6dhz0I1l3l4a2mqMWA1lM8-_6VvdT0HaL59VXIwM`,
        "Content-Type": "application/json",
    })
  }

  constructor(private http: HttpClient, private cookieService: CookieService) { }
  public getHttpData(url: any): Observable<any> {
    if (!environment.unauthorized_routes.includes(url)) {
      const user_info = this.cookieService.check('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : undefined
      if (!user_info) return throwError(() => ({ 'message': "Need to Login" }));
      this.httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "authorization": `Bearer ${user_info.token}`,
      })
    }
    return this.http.get<any>(this.baseUrl + url, this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        console.log('error --->', error);
        return throwError(() => new Error(error));
      })
    );;
  }
  public getHttpDatalogin(url: any): Observable<any> {
    if (!environment.unauthorized_routes.includes(url)) {
      // const user_info = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : undefined
      // console.log("user_info",user_info);
      
      this.httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // "authorization": `Bearer ${user_info.token}`,
      })
    }
    return this.http.get<any>(this.baseUrl + url, this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        console.log('error --->', error);
        return throwError(() => new Error(error));
      })
    );;
  }

  public getHttpDataWithoutBaseUrl(url: any): Observable<any> {
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        // this.openSnackBar();
        console.log('error --->', error);
        return throwError(() => new Error(error));
      })
    );;
  }

  public getHttpDataPost(url: any, body: any): Observable<any> {
    if (!environment.unauthorized_routes.includes(url)) {
      const user_info = this.cookieService.check('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : undefined
      if (!user_info) return throwError(() => ({ 'message': "Need to Login" }));
      this.httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "authorization": `Bearer ${user_info.token}`,
      })
    }
    return this.http.post<any>(this.baseUrl + url, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();

        return throwError(() => error.error);
      })
    )
  }

  public getHttpDataPostcalendarADD(url: any, body: any): Observable<any> {
    if (!environment.unauthorized_routes.includes(url)) {
      const user_info = this.cookieService.check('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : undefined
      if (!user_info) return throwError(() => ({ 'message': "Need to Login" }));
      this.httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "authorization": `Bearer ${user_info.token}`,
      })
    }
    return this.http.post<any>('https://m9mkuic6o9.execute-api.us-east-1.amazonaws.com/dev/' + url, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();

        return throwError(() => error.error);
      })
    )
  }

  public getHttpDataPostcalendar(url: any, body: any): Observable<any> {
    if (!environment.unauthorized_routes.includes(url)) {
      const user_info = this.cookieService.check('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : undefined
      if (!user_info) return throwError(() => ({ 'message': "Need to Login" }));
      this.httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "authorization": `Bearer ${user_info.token}`,
      })
    }
    return this.http.post<any>('https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/' + url, JSON.stringify(body), this.httpOptions).pipe(

 
      catchError((error) => {
        // this.openSnackBar();

        return throwError(() => error.error);
      })
    )
  }


  public getHttpDatacalendar(url: any): Observable<any> {
    if (!environment.unauthorized_routes.includes(url)) {
      const user_info = this.cookieService.check('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : undefined
      if (!user_info) return throwError(() => ({ 'message': "Need to Login" }));
      this.httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "authorization": `Bearer ${user_info.token}`,
      })
    }
    return this.http.get<any>('https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/' + url, this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        console.log('error --->', error);
        return throwError(() => new Error(error));
      })
    );
  }




  public getHttpDatalocation(url: any,body:any): Observable<any> {
    if (!environment.unauthorized_routes.includes(url)) {
      const user_info = this.cookieService.check('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : undefined
      if (!user_info) return throwError(() => ({ 'message': "Need to Login" }));
      this.httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "authorization": `Bearer ${user_info.token}`,
      })
    }
      return this.http.post<any>(this.baseUrl + url, JSON.stringify(body),this.httpOptions).pipe(
      catchError((error) => {
       return throwError(() => new Error(error));
      })
    );
  }





  public getHttpDataPostWithoutBaseUrl (url: any,body:any): Observable<any> {
    return this.http.post<any>(url, body).pipe(
      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }


  public getHttpHoroscopePost(url: any, body: any): Observable<any> {


    let astro_key = JSON.parse(this.cookieService.get('astro_key_values'))

    console.log("astro_key",astro_key);
    

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': 'Basic ' + btoa(astro_key.access_key + ':' + astro_key.secret_key),
      })
    }
    console.log("httpOptions2",httpOptions2);
    


    return this.http.post<any>(environment.astrology_api_url + url, JSON.stringify(body), httpOptions2).pipe(
      catchError((error) => {


        this.getHttpData('astro_decan_new_infos/get-astro-toolkit').subscribe({
          next: (response) => {
            console.log("astro_decan_new_infos/get-astro-toolkit",response);
            
            let obj = { 
              "access_key":response.results.access_key,
              "secret_key":response.results.secret_key
            }
            this.cookieService.set('astro_key_values',JSON.stringify(obj),{ path: '/', });
          }
        })
        // this.openSnackBar();
        return of(null).pipe(
          delay(2000),  // 2-second delay
          switchMap(() => this.getHttpHoroscopePost(url, body)) // Retry the function
        )
        // return  throwError(() => error.error)
      })
    )


  }

  public getRoomIDPost(): Observable<any> {
    return this.http.post<any>('https://api.videosdk.live/v2/rooms',JSON.stringify({}),this.httpOptionsroomID).pipe(
      catchError((error) => {
        // this.openSnackBar();
        return throwError(() => error.error);
      })
    )
  }


  public getHttpNewHoroscopePost(body: any): Observable<any> {
    // const user_info = this.cookieService.check('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : undefined
    // if (user_info) {
    //   this.httpOptions.headers = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     "authorization": `Bearer ${user_info.token}`,
    //   })
    // }

    console.log("httpOptions", body);





    return this.http.post<any>(environment.new_astrology_api_url, JSON.stringify(body), this.httpOptions3).pipe(
      catchError((error) => {
        // this.openSnackBar();
        return throwError(() => error.error);
      })
    )
  }


  httpViaPost(endpoint: any, jsonData: any): Observable<any> {
    // console.log("{{}{}{",jsonData);

    /* set common header */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': this.jwtToken
      })
    };
    return this.http.post(this.baseUrl + endpoint, jsonData, httpOptions)

  }


}
