import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';

@Component({
  selector: 'app-add-brodcasting',
  templateUrl: './add-brodcasting.component.html',
  styleUrls: ['./add-brodcasting.component.scss']
})
export class AddBrodcastingComponent {

  public userformdata: any = {};
  public editFormData: any = null;
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public addFormLoader: boolean = false;
  public loader: boolean = false;
  public paramsId:string=''
  public isedit: boolean = false;

  constructor(private apiservice: ApiservicesService, public matSnackBar: MatSnackBar, public router: Router, public activateRoute: ActivatedRoute) { }

  public ckeConfig: any = {
    toolbar: [
      { name: "editing", items: ["Scayt", "Find", "Replace", "SelectAll"]},
      { name: "clipboard",items: ["Cut","Copy","Paste","PasteText","PasteFromWord","-","Undo","Redo"]},
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      { name: "tools", items: ["Maximize", "ShowBlocks", "Preview", "Print", "Templates"]},
      { name: "document", items: ["Source"] },
      { name: "insert", items: ["Image", "Table", "HorizontalRule","SpecialChar","Iframe","imageExplorer"]},
      "/",
      { name: "basicstyles", items: ["Bold","Italic","Underline","Strike","Subscript","Superscript","-","RemoveFormat"]},
      { name: "paragraph", items: [ "NumberedList","BulletedList","-","Outdent","Indent","CreateDiv","-","Blockquote"]},
      { name: "justify", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"]},
      { name: "styles", items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"]}
    ]
  };

  statusarr: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  perennial: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ]


  initial() {
    this.userformdata={
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',

      fields: [
        {
          label: 'Title',
          name: 'title',
          value: this.editFormData && this.editFormData.title ? this.editFormData.title : '',
          type: 'text',
          classname: 'inputclass',
          validations: [{ rule: 'required', message: 'Title is Required' }]
        },

        {
          label: "Short Description",
          name: "shortDescription",
          type: "editor",
          value: this.editFormData && this.editFormData.shortDescription ? this.editFormData.shortDescription : '',
          ckeConfig: this.ckeConfig,
          hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Description is Required" },
          ]
        },

        {
          label: "Description",
          name: "description",
          type: "editor",
          value: this.editFormData && this.editFormData.description ? this.editFormData.description : '',
          ckeConfig: this.ckeConfig,
          hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Description is Required" },
          ]
        },

        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData ? this.editFormData.status : 1
        }
      ]
    }
  }

  ngOnInit() {
    this.paramsId = this.activateRoute.snapshot?.params["_id"]
    console.log("paramsId", this.activateRoute.snapshot.params)


    if (this.paramsId) {
    console.log("paramsId", this.paramsId)

      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {

          if (response.response.results.res && response.response.status == "success") {
          console.log("response====>", response.response.results.res);

            this.isedit = true;
            this.editFormData = response.response.results.res;
            console.log("editFormData",this.editFormData );
          }
        }
      });

      
      // this.editForm();
    }
    this.initial()
  }


  listenFormFieldChange(val: any) {
    console.log("val",val);

    if (val.field && val.field == "fromsubmit") {
      this.loader = true

      
      let formValueObj = val.fromval


      if(this.paramsId){
        formValueObj['_id'] = this.paramsId
      }


      this.apiservice.getHttpDataPost( `${this.paramsId ?'brodcasting/brodcasting-edit' :'brodcasting/brodcasting-data-add'}`, formValueObj).subscribe((response) => {
        console.log("responce",response);
        
        if (response && response.status == 'success') {
          this.loader = false
          console.log("jeuerytc67");
          
          // console.log("this.loader", this.loader);
          this.matSnackBar.open("Brodcast Added Successfully", "Ok", {
            duration: 3000
          });
          // this.dialogRef.close();
          this.router.navigateByUrl(`brod-casting`);
          // this.addFormLoader = false;
          // this.location.back();
        }

        if (response && response.status == 'error') {
          this.loader = false

          // this.addFormLoader = false;
        }
      })
      
    }
  }

}
