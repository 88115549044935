
<ng-container *ngIf="hideHeader" >
  <app-header></app-header>
</ng-container>

<ng-container *ngIf="!hideHeader">

  

  <div class="top-header">
    <div class="right-menu">
<!-- 
      <div class="top-btn homeIcon" *ngIf="this.login_user_details.userinfo.user_type === 'is_customer'" matTooltip="Cart" matBadge={{cart_Count}} style="margin-right: 16px;" matBadgeOverlap="true">
        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
      </div> -->
  
  
      <!-- <div class="top-btn homeIcon" matTooltip="Back to Home Page" (click)="backtoHome()">
        <i class="fa fa-home" aria-hidden="true"></i>
      </div> -->
  
  
      <!-- <div class="top-btn usericon">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      </div> -->
  
  
  
      <!-- <h3><span *ngIf="textLoader"></span> {{ userName }}</h3> -->
  
  
      <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </div>
  
      <mat-menu #rightdownmenu="matMenu">
  
        <!-- <button mat-menu-item (click)="myAccount()">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i> My Account
        </button>
  
        <button mat-menu-item (click)="changePass()">
          <i class="fa fa-unlock-alt" aria-hidden="true"></i> Change Password
        </button> -->
  
        <!-- <button *ngIf="(cookieData.userinfo.user_type === 'is_astrologer' ||cookieData.userinfo.user_type === 'is_tarotreader') && cookieData.userinfo?.unique_name" mat-menu-item (click)="navigateToPath('profile/' + cookieData.userinfo?.unique_name)">
          <i class="fa fa-user" aria-hidden="true"></i> View Profile
        </button>
  
  
        <button *ngIf="cookieData.userinfo.user_type ==='is_astrologer' || cookieData.userinfo.user_type === 'is_tarotreader'" mat-menu-item (click)="navigateToPath('astrologer-dashboard/profile/edit/' + cookieData.userinfo._id)">
          <i class="fa fas fa-edit" aria-hidden="true"></i> {{cookieData.userinfo?.unique_name?"Edit" : "Setup"}} Profile
        </button> -->
  
  
        <button mat-menu-item (click)="logoutfintion()">
          <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
        </button>
  
  
      </mat-menu>
  
  
  
    </div>
  </div>
  
  
  <div class="logo-wrapper">
    <div class="logo-sub-wrapper">
      <img src="https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/logobanner.webp" alt="" />
    </div>
  </div>


  
  
  <div class="header_mainwrapper">
    <div class="header_subwrapper">
      <div class="menu-wrapper" >
        <ul>
  

          
  
          <!-- Dashboard Navigation -->
          <li routerLinkActive="active">
            <a class="active" > Tarot Toolkit</a>
          </li>
  
  
          <!-- User List Navigation -->
       
  
        </ul>
      </div>
    </div>
  </div>
</ng-container>


<div class="tarotCardBody">

    <app-tarot-menu></app-tarot-menu>

    <div class="tarotCardWrapperStap3">

        <div class="cardSet3">


            <div class="cardCon">
                <ng-container *ngIf="!cardCurrentImage['Past']">
                    <div class="cardConTop" (click)="selectRandomImage('Past')"></div>
                </ng-container>


                <ng-container *ngIf="cardCurrentImage['Past']">
                  <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                    <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Past']" class="card_image" />
                  </div>
                </ng-container>

                <div class="cardConBottom">
                  <span>1</span>
                  <strong>Past</strong>
                </div>
            </div>


            <div class="cardCon">

                <ng-container *ngIf="!cardCurrentImage['Present']">
                    <div class="cardConTop" (click)="selectRandomImage('Present')"></div>
                </ng-container>


                <ng-container *ngIf="cardCurrentImage['Present']">
                  <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                  <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Present']" class="card_image" />
                  </div>
                </ng-container>

                <div class="cardConBottom">
                  <span>2</span>
                  <strong>Present</strong>
                </div>

            </div>



            <div class="cardCon">


              <ng-container *ngIf="!cardCurrentImage['Future']">
                  <div class="cardConTop" (click)="selectRandomImage('Future')"></div>
              </ng-container>


              <ng-container *ngIf="cardCurrentImage['Future']">
                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                  <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Future']" class="card_image" />
                </div>
              </ng-container>

              <div class="cardConBottom">
                <span>3</span>
                  <strong>Future</strong>
              </div>



            </div>


        </div>


    </div>


</div>

<app-footer></app-footer>