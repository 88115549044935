<app-header></app-header>

<div class="tarotCardBody">

    <app-tarot-menu></app-tarot-menu>

    <div class="tarotCardWrapperStap2">

        <div class="cardSet2">

            <!-- card Row1 -->

            <div class="cardSetRow1">

                <!-- card Con -->

                <div class="cardCon">
                    <ng-container *ngIf="!cardCurrentImage['Past']">
                        <div class="cardConTop" (click)="selectRandomImage('Past')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Past']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Past']" class="card_image" />
                        </div>
                      </ng-container>

                    <div class="cardConBottom">
                        <strong>3</strong>
                        <span>Past</span>

                    </div>



                </div>

                <!-- card Con end-->

                <!-- card Con -->

                <div class="cardCon">
                    <ng-container *ngIf="!cardCurrentImage['Future']">
                        <div class="cardConTop" (click)="selectRandomImage('Future')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Future']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Future']" class="card_image" />
                        </div>
                      </ng-container>

                    <div class="cardConBottom">
                        <strong>4</strong>
                        <span>Future</span>

                    </div>



                </div>

                <!-- card Con end-->


            </div>

            <!-- card Row1 end-->

            <!-- card Row2 -->

            <div class="cardSetRow2">

                <!-- card Con -->

                <div class="cardCon">
                    <ng-container *ngIf="!cardCurrentImage['Public']">
                        <div class="cardConTop" (click)="selectRandomImage('Public')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Public']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Public']" class="card_image" />
                        </div>
                      </ng-container>

                    <div class="cardConBottom">
                        <strong>6</strong>
                        <span>Public/Conscious</span>

                    </div>



                </div>

                <!-- card Con end-->

                        <!-- card Con -->

                        <div class="cardCon cardConsub1" (click)="cardConsub1()" [ngClass]= "addtopblock==true ? 'addtopblock' : '' ">
                            <ng-container *ngIf="!cardCurrentImage['Aspect']">
                                <div class="cardConTop" (click)="selectRandomImage('Aspect')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['Aspect']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Aspect']" class="card_image" />
                                </div>
                              </ng-container>
        
                            <div class="cardConBottom">
                                <strong>1</strong>
                                <span>Central Aspect</span>
        
                            </div>
        
        
        
                        </div>
        
                        <!-- card Con end-->

                        <!-- card Con -->

                        <div class="cardCon cardConsub2" (click)="cardConsub2()" [ngClass]= "addtopblock2==true ? 'addtopblock2' : '' ">
                            <ng-container *ngIf="!cardCurrentImage['Aspect2']">
                                <div class="cardConTop" (click)="selectRandomImage('Aspect2')"></div>
                              </ng-container>
                              <ng-container *ngIf="cardCurrentImage['Aspect2']">
                                <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                                <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Aspect2']" class="card_image" />
                                </div>
                              </ng-container>
        
                            <div class="cardConBottom">
                                <strong>2</strong>
                                <span>Central Aspect</span>
        
                            </div>
        
        
        
                        </div>
        
                        <!-- card Con end-->

                <!-- card Con -->

                <div class="cardCon">
                    <ng-container *ngIf="!cardCurrentImage['Unconscious']">
                        <div class="cardConTop" (click)="selectRandomImage('Unconscious')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Unconscious']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Unconscious']" class="card_image" />
                        </div>
                      </ng-container>

                    <div class="cardConBottom">
                        <strong>5</strong>
                        <span>Private/Unconscious</span>

                    </div>



                </div>

                <!-- card Con end-->


         


            </div>

            <!-- card Row2 end-->

            <!-- card Row2 -->

            <div class="cardSetRow3">

                <!-- card Con -->

                <div class="cardCon">
                    <ng-container *ngIf="!cardCurrentImage['Identity']">
                        <div class="cardConTop" (click)="selectRandomImage('Identity')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Identity']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Identity']" class="card_image" />
                        </div>
                      </ng-container>

                    <div class="cardConBottom">
                        <strong>7</strong>
                        <span>Identity</span>

                    </div>



                </div>

                <!-- card Con end-->

                <!-- card Con -->

                <div class="cardCon">
                    <ng-container *ngIf="!cardCurrentImage['Identity2']">
                        <div class="cardConTop" (click)="selectRandomImage('Identity2')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Identity2']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Identity2']" class="card_image" />
                        </div>
                      </ng-container>

                    <div class="cardConBottom">
                        <strong>8</strong>
                        <span>Identity</span>

                    </div>



                </div>

                <!-- card Con end-->


            </div>

            <!-- card Row2 end-->

            <!-- card Row2 -->

            <div class="cardSetRow4">

                <!-- card Con -->

                <div class="cardCon">
                    <ng-container *ngIf="!cardCurrentImage['Desire']">
                        <div class="cardConTop" (click)="selectRandomImage('Desire')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Desire']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Desire']" class="card_image" />
                        </div>
                      </ng-container>

                    <div class="cardConBottom">
                        <strong>9</strong>
                        <span>Desire/Fear</span>

                    </div>



                </div>

                <!-- card Con end-->

                <!-- card Con -->

                <div class="cardCon">
                    <ng-container *ngIf="!cardCurrentImage['Desire2']">
                        <div class="cardConTop" (click)="selectRandomImage('Desire2')"></div>
                      </ng-container>
                      <ng-container *ngIf="cardCurrentImage['Desire2']">
                        <div class="cardConTop" [ngClass]= "addImgcss==true ? 'classadd' : '' ">
                        <img [src]="'assets/tarot-card-images/' + cardCurrentImage['Desire2']" class="card_image" />
                        </div>
                      </ng-container>

                    <div class="cardConBottom">
                        <strong>10</strong>
                        <span>Desire/Fear</span>

                    </div>



                </div>

                <!-- card Con end-->


            </div>

            <!-- card Row2 end-->



        </div>


    </div>


</div>

<app-footer></app-footer>