import { Component } from '@angular/core';

@Component({
  selector: 'app-card-spread4',
  templateUrl: './card-spread4.component.html',
  styleUrls: ['./card-spread4.component.scss']
})
export class CardSpread4Component {
  public addImgcss:boolean=false;

  public addtopblock:boolean=false;
  public addtopblock2:boolean=false;

  public cardImageSource: any = [
    'Debauch_Small.jpg',
    'Domonion_Small.jpg',
    'eight_of_Wands_small_image.jpg',
    'Four_of_Swords_Truce_01.jpg',
    'Four_of_Wands_small_image.jpg',
    'Futility_Small.jpg',
    'hanged-man.jpg',
    'hermit.jpg',
    'Hierophant_Small.jpg',
    'Knight_of_Cups_small_image.jpg',
    'Nine_of_Disks_Gain_small.jpg',
    'orphic-jung-tarot_title_image4.png',
    'orphic-jung-tarot_title_image7.png',
    'orphic-jung-tarot_title_image8.png'
  ]
  public cardCurrentImage: any = {}

  selectRandomImage(value: string) {
    if (this.cardImageSource.length > 0) {
      // Select a random index
      const randomIndex = Math.floor(Math.random() * this.cardImageSource.length);
  
      // Get the image at the random index
      const selectedImage = this.cardImageSource[randomIndex];
  
      // Assign the selected image to the `cardCurrentImage` object
      this.cardCurrentImage[value] = selectedImage;
  
      // Remove the selected image from the array to avoid it being selected again
      this.cardImageSource.splice(randomIndex, 1);      
  
      // Update the CSS class or perform any other action
      this.addImgcss = true;
    } else {
      console.log('No more images to select.');
    }
  }
  cardConsub1(){ 
     this.addtopblock=true;
     this.addtopblock2=false;
    }

  cardConsub2(){  
    this.addtopblock2=true;
    this.addtopblock=false;
  }

  ngOnInit() {
    

     this.cardImageSource.forEach((src:any) => {
      const img = document.createElement("img");
      img.src = `https://divineinfinitebeing.influxiq.com/assets/tarot-card-images/${src}` ;
     });
    
  }

}
