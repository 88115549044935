import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public router: Router) {
    // console.log("environment========>", environment)
  }

  title = 'divine-infinite-being-angular-ui';
  color: ThemePalette = 'accent';
  mode: ProgressBarMode = 'indeterminate';
  public loader: boolean = false;

  ngOnInit() {
    // Loader Config
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart:
          this.loader = true;
          break;
        case event instanceof NavigationEnd:
          this.loader = false;
          break;
        case event instanceof NavigationError:
          this.loader = false;
          break;
      }
    });
  }
}
