import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from '../../services/apiservices.service';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public cookieData: any = {};
  public userName: any = {};
  public lastLogin: any = {};
  classToggled = false;
  public activemenu: any = '';
  public login_user_details: any = {}
  public textLoader: boolean = false
  public cart_Count:number = 0

  public uiUrl = environment.ui_url;
  public toggleNav() {
    // console.log('aaaa');
    this.classToggled = !this.classToggled;
  }

  constructor(public router: Router, private apiService: ApiservicesService, public activateRoute: ActivatedRoute, private cookieService: CookieService, public matSnackBar: MatSnackBar) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    this.login_user_details = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      // console.log("cookieData", this.cookieData.userinfo._id);
      this.userName = this.cookieData.userinfo.name
      this.lastLogin = this.cookieData.userinfo.last_login_time
    }


    if (this.router.url.includes('dashboard')) this.activemenu = 'dashboard';


    if(this.cookieData.userinfo.user_type=='is_customer'){
      console.log("jfhjfhfdughu");
      
      let obj = {
        id:this.login_user_details.userinfo._id
      }
      this.apiService.getHttpDataPost('cart/cart_data_count',obj).subscribe({
        next:(value)=> {
          this.cart_Count = value.count
        },error:(error)=>{
        }
      })
    }


    
  }

  public logout() {
    this.apiService.getHttpData(`user/logout/${this.cookieData.userinfo.username}`).subscribe({
      next: (response) => {
        // this.loader = false;
        this.cookieService.delete('login_user_details', '/')
        this.cookieService.delete('loggedin_user', '/')
        // console.log("logout response", response)
        this.matSnackBar.open(response.message, "Ok", {
          duration: 4000
        });
        this.router.navigateByUrl('/')

      },
      error: (error: any) => {

      }
    })
  }
  public changePass() {
    // console.log("changepass", this.cookieData.userinfo.username);
    this.apiService.getHttpData(`user/request-verification-code?username=${this.cookieData.userinfo.username}`).subscribe({
      next: (response) => {
        this.matSnackBar.open(response.message, "Ok", {
          duration: 4000
        });
        this.router.navigateByUrl(`/forget-password/set-password?username=${this.cookieData.userinfo.username}`);
      },
      error: (error: any) => {

      }
    })

  }


  public myAccount() {

    this.router.navigateByUrl(`/my-account/account-info/${this.cookieData.userinfo._id}`);

  }
  backtoHome(){
    window.location.href=`https://divineinfinitebeing.com/`;
  }

  dashboardPage() {
    
    if (this.login_user_details.userinfo.user_type === 'is_admin') this.router.navigateByUrl(`/admin-dashboard`);
    else if (this.login_user_details.userinfo.user_type === 'is_astrologer' || this.login_user_details.userinfo.user_type === 'is_tarotreader') this.router.navigateByUrl(`/astrologer-dashboard`);
    else if (this.login_user_details.userinfo.user_type === 'is_social_advo' || this.login_user_details.userinfo.user_type === 'is_customer') this.router.navigateByUrl(`/customer-dashboard`);
    else if (this.login_user_details.userinfo.user_type === 'is_Perennial_Mandalism') this.router.navigateByUrl(`/perennial-mandalism-dashboard`);
    else if (this.login_user_details.userinfo.user_type === 'is_affiliate') this.router.navigateByUrl('/affiate-dashboard')
  }
  navigateToPath(path: string) {
    this.router.navigateByUrl(path)
    
  }
  currentPath(): string {
    return this.router.url
  }
}
