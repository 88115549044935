<div class="top-header">
  <div class="right-menu">

    <div class="top-btn homeIcon" *ngIf="this.login_user_details.userinfo.user_type === 'is_customer'" matTooltip="Cart" matBadge={{cart_Count}} style="margin-right: 16px;" matBadgeOverlap="true">
      <i class="fa fa-shopping-cart" aria-hidden="true"></i>
    </div>


    <div class="top-btn homeIcon" matTooltip="Back to Home Page" (click)="backtoHome()">
      <i class="fa fa-home" aria-hidden="true"></i>
    </div>


    <div class="top-btn usericon">
      <i class="fa fa-user-circle-o" aria-hidden="true"></i>
    </div>



    <h3><span *ngIf="textLoader"></span> {{ userName }}</h3>


    <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </div>

    <mat-menu #rightdownmenu="matMenu">

      <button mat-menu-item (click)="myAccount()">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i> My Account
      </button>

      <button mat-menu-item (click)="changePass()">
        <i class="fa fa-unlock-alt" aria-hidden="true"></i> Change Password
      </button>

      <button *ngIf="(cookieData.userinfo.user_type === 'is_astrologer' ||cookieData.userinfo.user_type === 'is_tarotreader') && cookieData.userinfo?.unique_name" mat-menu-item (click)="navigateToPath('profile/' + cookieData.userinfo?.unique_name)">
        <i class="fa fa-user" aria-hidden="true"></i> View Profile
      </button>


      <button *ngIf="cookieData.userinfo.user_type ==='is_astrologer' || cookieData.userinfo.user_type === 'is_tarotreader'" mat-menu-item (click)="navigateToPath('astrologer-dashboard/profile/edit/' + cookieData.userinfo._id)">
        <i class="fa fas fa-edit" aria-hidden="true"></i> {{cookieData.userinfo?.unique_name?"Edit" : "Setup"}} Profile
      </button>


      <button mat-menu-item (click)="logout()">
        <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
      </button>


    </mat-menu>



  </div>
</div>


<div class="logo-wrapper">
  <div class="logo-sub-wrapper">
    <img src="https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/logobanner.webp" alt="" />
  </div>
</div>


<div class="header_mainwrapper">
  <div class="header_subwrapper">
    <div class="menu-wrapper" [class.showmenu]="classToggled">
      <ul>


        <!-- Dashboard Navigation -->
        <li routerLinkActive="active">
          <a [ngClass]="currentPath() == '/admin-dashboard' ||currentPath() == '/astrologer-dashboard' ||currentPath() == '/customer-dashboard' || currentPath() == '/affiate-dashboard' || currentPath() == '/perennial-mandalism-dashboard' ? 'active': ''" (click)="dashboardPage()">Dashboard</a>
        </li>


        <!-- User List Navigation -->
        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [ngClass]="currentPath() == '/admin-dashboard/user-list' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/user-list')">Users</a>
        </li>


        <!-- Training Navigations -->
        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [matMenuTriggerFor]="Training" [ngClass]="currentPath() == '/admin-dashboard/training/training-center' ||currentPath() == '/admin-dashboard/training/training-category' ||currentPath() == '/admin-dashboard/training/training-lesson' ||currentPath() == '/admin-dashboard/training/quiz'||currentPath() == '/admin-dashboard/training/assignment-list'? 'active': ''">
            Training
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
        </li>


        <mat-menu #Training="matMenu">
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/training/training-center')">Training Center</button>
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/training/training-category')">Category</button>
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/training/training-lesson')">Lesson</button>
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/training/quiz')">Quiz</button>
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/training/assignment-list')">Assignment</button>
        </mat-menu>

        <!-- Training Center -->
        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_astrologer' || this.login_user_details.userinfo.user_type === 'is_tarotreader'">
          <a [ngClass]="currentPath() == '/astrologer-dashboard/training-center'? 'active': ''" (click)="navigateToPath('astrologer-dashboard/training-center')">Training Center</a>
        </li>


        <!-- Astro Tollkit -->
        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_astrologer'">
          <a [ngClass]="this.router.url.includes('/horoscope') ? 'active' : ''" (click)="navigateToPath('horoscope?tab=western_horoscope_v2')">Astro Toolkit</a>
        </li>

        <!-- Role Navigation -->

        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_astrologer'">
          <a [ngClass]="currentPath() == '/showcasespread/showcase-spread' ? 'active' : ''"(click)="navigateToPath('/showcasespread/showcase-spread')">
            Showcase spread
          </a>
        </li>

        <!--Webiner navigate  -->
        <!-- <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [ngClass]="currentPath() == '/admin-dashboard/webiner' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/webiner')">
            Webinar
          </a>
        </li> -->

        <!-- ---------Package Navigate---------      -->
        <!-- <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [ngClass]="currentPath() == '/admin-dashboard/package' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/package')">
            Package
          </a>
        </li> -->

        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [matMenuTriggerFor]="Toolkit" [ngClass]="
              currentPath() == '/admin-dashboard/tarot-spreads' ||
              currentPath() == '/horosceop' ||
              currentPath() == '/horosceop' ||
              currentPath() == '/tarot-card/tarotCard5' ||
              currentPath() == '/tarot-card/tarotCard6' ||
              currentPath() == '/tarot-card/tarotCard7' ||
              currentPath() == '/tarot-card/tarotCard8' ||
              currentPath() == '/tarot-card/tarotCard9' ||
              currentPath() == '/tarot-card/tarotCard2' ||
              currentPath() == '/tarot-card/tarotCard3' ||
              currentPath() == '/tarot-card/tarotCard4' ||
              currentPath() == '/tarot-card/tarotCard'
                ? 'active'
                : ''
            ">Toolkit <i class="fa fa-angle-down" aria-hidden="true"></i></a>
        </li>


        <mat-menu #Toolkit="matMenu">
          <button mat-menu-item [ngClass]="currentPath() == '/admin-dashboard/tarot-spreads' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/tarot-spreads')">
            Tarot Spreads
          </button>

          <button mat-menu-item [ngClass]="currentPath() == 'horoscope' ? 'active' : ''"(click)="navigateToPath('horoscope?tab=western_horoscope_v2')">
            Astro Toolkit
          </button>

          <button mat-menu-item [ngClass]="
              currentPath() == '/tarot-card/tarotCard' ||
              currentPath() == '/tarot-card/tarotCard2' ||
              currentPath() == '/tarot-card/tarotCard3' ||
              currentPath() == '/tarot-card/tarotCard4' ||
              currentPath() == '/tarot-card/tarotCard5' ||
              currentPath() == '/tarot-card/tarotCard6' ||
              currentPath() == '/tarot-card/tarotCard7' ||
              currentPath() == '/tarot-card/tarotCard8' ||
              currentPath() == '/tarot-card/tarotCard9'
                ? 'active'
                : ''
            " (click)="navigateToPath('tarot-card/tarotCard3')">
            Tarot Toolkit
          </button>





          <button mat-menu-item [ngClass]="currentPath() == 'admin-dashboard/tarot-spreads/tarot-card/tarotcard-list' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/tarot-spreads/tarot-card/tarotcard-list')">
            Tarot Card
          </button>


        </mat-menu>



        <!-- Testimonial Navigation -->
        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [matMenuTriggerFor]="Testimonial" [ngClass]="currentPath() =='/admin-dashboard/testimonial/request-testimonial' || currentPath() == '/admin-dashboard/testimonial/create-testimonial'? 'active': ''">
            Manage Testimonial
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
        </li>



        <mat-menu #Testimonial="matMenu">

          <button mat-menu-item (click)="navigateToPath(this.login_user_details.userinfo.user_type === 'is_admin'? 'admin-dashboard/testimonial/create-testimonial': this.login_user_details.userinfo.user_type ==='is_astrologer'? 'astrologer-dashboard/testimonial/create-testimonial': '')">
            Create Testimonial
          </button>

          <button mat-menu-item (click)="navigateToPath(this.login_user_details.userinfo.user_type === 'is_admin'? 'admin-dashboard/testimonial/request-testimonial': this.login_user_details.userinfo.user_type ==='is_astrologer'? 'astrologer-dashboard/testimonial/request-testimonial': '')">
            Request Testimonial
          </button>

        </mat-menu>



        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_astrologer' || this.login_user_details.userinfo.user_type === 'is_tarotreader'">
          <a [ngClass]="currentPath() =='/astrologer-dashboard/testimonial/request-testimonial'? 'active': ''" (click)="navigateToPath('astrologer-dashboard/testimonial/request-testimonial')">
            Manage Testimonial
          </a>
        </li>



        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_astrologer'">
          <a [ngClass]="
              currentPath() == '/tarot-card/tarotCard' ||
              currentPath() == '/tarot-card/tarotCard2' ||
              currentPath() == '/tarot-card/tarotCard3' ||
              currentPath() == '/tarot-card/tarotCard4' ||
              currentPath() == '/tarot-card/tarotCard5' ||
              currentPath() == '/tarot-card/tarotCard6' ||
              currentPath() == '/tarot-card/tarotCard7' ||
              currentPath() == '/tarot-card/tarotCard8' ||
              currentPath() == '/tarot-card/tarotCard9'
                ? 'active'
                : ''
            " (click)="navigateToPath('tarot-card/tarotCard3')">Tarot Toolkit</a>
        </li>




        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_social_advo'">
          <a [ngClass]="currentPath() == '/customer-dashboard/social-advo' ? 'active' : ''" (click)="navigateToPath('customer-dashboard/social-advo')">
            Social Advo Posts
          </a>
        </li>



        <!-- -----------------------------------payments--------------------- -->

        <!-- <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [ngClass]="currentPath() == '/admin-dashboard/payment' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/payment')">
            Payment
          </a>
        </li> -->




        <!-- Calendar Navigations -->

        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_astrologer'">
          <a [matMenuTriggerFor]="MiscellaneousAstrologer" [ngClass]="currentPath() =='/astrologer-dashboard/calendar-management/event-list' ||currentPath() =='/astrologer-dashboard/calendar-management/create-event' ||currentPath() =='/astrologer-dashboard/calendar-management/available-slots' ||currentPath() =='/astrologer-dashboard/package'? 'active': ''">
            Miscellaneous 
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
        </li>




        <mat-menu #MiscellaneousAstrologer="matMenu">

          <button mat-menu-item (click)="navigateToPath('astrologer-dashboard/calendar-management/event-list')">
            Calendar Management
          </button>

          <button mat-menu-item (click)="navigateToPath(this.login_user_details.userinfo.user_type === 'is_astrologer' ? 'astrologer-dashboard/package' : '')">
            Package
          </button>
        </mat-menu>




        <li *ngIf="this.login_user_details.userinfo.user_type !== 'is_affiliate' && this.login_user_details.userinfo.user_type !=='is_Perennial_Mandalism'">
          <a [ngClass]="currentPath() =='/astrologer-dashboard/calendar-management/event-list'? 'active': ''" (click)="navigateToPath('calender')">
            Calender 
          </a>
        </li>




        <mat-menu #Shop="matMenu">
          <button mat-menu-item [ngClass]="currentPath() == '/shop/tarrot-card-books' ? 'active' : ''"
            (click)="navigateToPath('shop/tarrot-card-books')">
            Tarrot Card and Books
          </button>
          <button mat-menu-item [ngClass]="currentPath() == 'shop/posters' ? 'active' : ''"
            (click)="navigateToPath('shop/posters')">
            Posters
          </button>
        </mat-menu>


        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [matMenuTriggerFor]="Miscellaneous" [ngClass]="
              currentPath() == '/admin-dashboard/social-advo' ||
              currentPath() == '' ||
              currentPath() == '/admin-dashboard/role'||
              currentPath() == '/quarter-management' || currentPath() == '/admin-dashboard/webiner' || currentPath() == '/admin-dashboard/package' || currentPath() == '/admin-dashboard/payment' || currentPath() == '/admin-dashboard/report' || currentPath() == '/admin-dashboard/calender-of-events' 
                ? 'active'
                : ''
            ">Miscellaneous <i class="fa fa-angle-down" aria-hidden="true"></i></a>
        </li>



        <mat-menu #Miscellaneous="matMenu">


          <button mat-menu-item [ngClass]="currentPath() == '/admin-dashboard/role' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/role')">
            Role
          </button>

          <button mat-menu-item [ngClass]="currentPath() == '/admin-dashboard/social-advo' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/social-advo')">
            Social Advo Post
          </button>


          <button mat-menu-item [ngClass]="currentPath() == '/admin-dashboard/quarter' ? 'active' : ''" (click)="navigateToPath('/admin-dashboard/quarter')">
            Quarter Management
          </button>


          <button mat-menu-item [ngClass]="currentPath() == '/admin-dashboard/class_configure' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/class_configure')">
            Class Configuration
          </button>


          <button *ngIf="this.login_user_details.userinfo.user_type === 'is_admin' || this.login_user_details.userinfo.user_type === 'is_astrologer' || this.login_user_details.userinfo.user_type === 'is_tarotreader'" mat-menu-item [ngClass]="currentPath() == '/admin-dashboard/assignment-notification' ? 'active' : ''" (click)="navigateToPath('admin-dashboard/assignment-notification')">
            Assignment Notification
          </button>

          <button *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'" mat-menu-item (click)="navigateToPath('/admin-dashboard/spiritual-wisdom')">Spiritual Wisdom</button>

          <button *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'" mat-menu-item (click)="navigateToPath('/admin-dashboard/spiritual-wisdom/spiritual-youtube-form')">Spiritual Wisdom Video</button>

          <button *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'" mat-menu-item (click)="navigateToPath('admin-dashboard/calender-of-events')">Calender Of Events</button>

          <button mat-menu-item (click)="navigateToPath('admin-dashboard/webiner')">Webinar</button>

          <button mat-menu-item (click)="navigateToPath('admin-dashboard/package')">Package</button>
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/payment')">Payment</button>
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/report')">Report</button>
          


        </mat-menu>



        <li *ngIf="this.login_user_details.userinfo.user_type !== 'is_affiliate' && this.login_user_details.userinfo. user_type !== 'is_Perennial_Mandalism'">
          <a [matMenuTriggerFor]="product" [ngClass]="currentPath() == '/product' ? 'active': ''">
            Product  
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
        </li>


        <mat-menu #product="matMenu">
          <button mat-menu-item [ngClass]="currentPath() == 'product/category' ? 'active' : ''"
            (click)="navigateToPath('product/category')">
            Product Category
          </button>
          <button mat-menu-item [ngClass]="currentPath() == 'product/management' ? 'active' : ''"
            (click)="navigateToPath('product/management')">
            Product Management
          </button>
        </mat-menu>


        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_customer'">
          <a [ngClass]="currentPath() == '/profile' ? 'active' : ''" (click)="navigateToPath('/profile/astrologer/'+this.cookieData.userinfo.service_provider)">Provider Menu</a>
        </li>

        <li routerLinkActive="active">
          <a [ngClass]="currentPath() == '/blog-manegement' ? 'active': ''" (click)="navigateToPath('/blog-manegement')">Blog</a>
        </li>

        <!-- <li routerLinkActive="active">
          <a [ngClass]="currentPath() == '/video-management' ? 'active': ''" (click)="navigateToPath('/video-management')">Video</a>
        </li> -->

        <li >
          <a [matMenuTriggerFor]="video" [ngClass]="(currentPath() == '/video-management' || currentPath() == '/confarence-room')  ? 'active': ''">
            Video  
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
        </li>
        <mat-menu #video="matMenu">
          <button mat-menu-item [ngClass]="currentPath() == '/video-management' ? 'active' : ''"
            (click)="navigateToPath('video-management')">
            Video List
          </button>
          <button  *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'" mat-menu-item [ngClass]="currentPath() == 'confarence-room' ? 'active' : ''"
            (click)="navigateToPath('confarence-room')">
            Session Recordings
          </button>
        </mat-menu>

        <li routerLinkActive="active">
          <a [ngClass]="currentPath() == '/brod-casting' ? 'active': ''" (click)="navigateToPath('/brod-casting')">Brod Casting</a>
        </li>

        <li routerLinkActive="active" *ngIf="this.login_user_details.userinfo.user_type === 'is_Perennial_Mandalism'">
          <a [href]="this.uiUrl + '/calendar-of-events?user_id=' + login_user_details.userinfo._id" target="_blank">Calendar of Events</a>
        </li>
        
        <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [matMenuTriggerFor]="PerrenialMandalism" [ngClass]=" currentPath() == '/admin-dashboard/perrenial-mandalism/dashboard-content-list'
                ? 'active'
                : ''
            ">Perennial Mandalism <i class="fa fa-angle-down" aria-hidden="true"></i></a>
        </li>



        <mat-menu #PerrenialMandalism="matMenu">
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/perrenial-mandalism/dashboard-content-list')">Manage Dashboard Contents</button>
        </mat-menu>


        <!-- <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a [ngClass]="currentPath() == '/confarence-room' ? 'active': ''" (click)="navigateToPath('/confarence-room')">Conference</a>
        </li> -->

      </ul>
    </div>
    <div (click)="toggleNav()" class="toggleBtn">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
  </div>
</div>